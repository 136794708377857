import { colors } from "../../assets/theme";

export const Previous = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading: boolean;
}) => (
  <svg
    data-testid="previous"
    style={{ paddingRight: "5px", cursor: "pointer" }}
    onClick={onClick}
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.9C0 4.65979 0 3.53968 0.435974 2.68404C0.819467 1.93139 1.43139 1.31947 2.18404 0.935974C3.03968 0.5 4.15979 0.5 6.4 0.5H18.6C20.8402 0.5 21.9603 0.5 22.816 0.935974C23.5686 1.31947 24.1805 1.93139 24.564 2.68404C25 3.53968 25 4.65979 25 6.9V19.1C25 21.3402 25 22.4603 24.564 23.316C24.1805 24.0686 23.5686 24.6805 22.816 25.064C21.9603 25.5 20.8402 25.5 18.6 25.5H6.4C4.15979 25.5 3.03968 25.5 2.18404 25.064C1.43139 24.6805 0.819467 24.0686 0.435974 23.316C0 22.4603 0 21.3402 0 19.1V6.9Z"
      fill="#7D4CDB"
      fillOpacity="0.1"
    />
    <path
      d="M14.5833 8.1389L10.4166 13.6945L14.5833 19.25"
      stroke={!isLoading ? colors.plPurple06 : colors.plGrey02}
      strokeWidth="2"
    />
  </svg>
);
