import styled from "styled-components";
import { PremierLeagueLogoWhite } from "../assets/PremierLeagueLogoWhite";
import { Text } from "grommet";
import { colors } from "../assets/theme";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 200px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const ErrorPage = ({ error }: { error?: string }) => {
  const getErrorText = () => {
    switch (true) {
      case error === "404":
        return (
          <Text color={colors.plMagenta1} weight="bold" size="medium">
            Uh-oh! We couldn't find the page you are looking for.
          </Text>
        );
      default:
        return (
          <Text color={colors.plMagenta1} weight="bold" size="medium">
            Generic Error
          </Text>
        );
    }
  };

  return (
    <Container>
      <PremierLeagueLogoWhite size="large" />
      <h4>{error}</h4>
      {getErrorText()}
      <p>
        <a href="/">Go to the Dashboard</a>
      </p>
    </Container>
  );
};
