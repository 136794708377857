import { AppUserViewModel, Day, OccurrenceViewModel } from "../types";
import { dateFormatter, getIconForInteractiveBox, RowEventBox } from "../utils";
import { DayInfoTooltip } from "./DayInfoTooltip";
import React from "react";

export const OccurrencesRow = ({
  days,
  appUser,
  iconSize,
  onSelectDate,
  isLastChild,
}: {
  days: Day[];
  appUser: AppUserViewModel;
  iconSize: string;
  onSelectDate: (date: string, occurrences: OccurrenceViewModel[]) => void;
  isLastChild: boolean;
}) => {
  if (days?.length === 0) {
    return <></>;
  }

  const getDataTestId = (username: string, date: string) => {
    const dateFormatted = dateFormatter(date);
    return `row-event-box-${username
      .replace(" ", "")
      .toLowerCase()}-${dateFormatted}`;
  };

  return (
    <>
      {days?.map((day, i) => {
        const isToday =
          new Date(
            `${day.number} ${day.month} ${new Date().getFullYear()}`
          ).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
        return (
          <DayInfoTooltip
            appUser={appUser}
            day={day}
            key={`${day.number}${day.weekDay}${i}`}
          >
            <RowEventBox
              data-testid={getDataTestId(appUser.name, day.date)}
              isLastChild={isLastChild}
              clickable
              onClick={() => onSelectDate(day.date, day.occurrences)}
              greenSideBorder={isToday}
              greyOverlay={
                day.weekDay === "saturday" || day.weekDay === "sunday"
              }
            >
              {getIconForInteractiveBox(day, iconSize, false, false, true)}
            </RowEventBox>
          </DayInfoTooltip>
        );
      })}
    </>
  );
};
