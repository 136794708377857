import axios from "axios";
import { AppUserViewModel, Department, Team } from "../types";

export default class DepartmentService {
  getDepartment = async (payload: { id: string }): Promise<Department> => {
    const { id } = payload;
    const { data } = await axios.get(`/departments?$filter=(id eq ${id})`);

    const teamsWithoutHiddenUsers = data[0].teams
      .filter((team: Team) => !team.hidden)
      .map((team: Team) => {
        const visibleAppUsers = team.appUsers
          .map((user: AppUserViewModel) => ({
            ...user,
            managerOf: team.appUsers
              .filter((u: AppUserViewModel) => u.managerId === user.id)
              .map((us: AppUserViewModel) => us.id),
            name: user.name.replace(/[.]/g, " ").replace(/^(ext)/gi, ""),
          }))
          .filter((user) => user.plasActive && !user.hidden);

        return {
          ...team,
          appUsers: visibleAppUsers,
        };
      });

    const department = { ...data[0], teams: teamsWithoutHiddenUsers };

    return department;
  };
}
