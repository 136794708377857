import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { colors } from "../../../assets/theme";
import { Box } from "grommet";

export const Travel35 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="35px"
      height="35px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,17.5px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,17.5px)"
            : "unset",
        background: colors.plBlue1,
      }}
    >
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="35" height="35" fill="#2B59FC" />
        <path
          d="M16.4286 10.6H18.5714V8.3H16.4286V10.6ZM17.5 17.5C16.1429 17.5 14.8482 17.1981 13.6161 16.5944C12.3839 15.9906 11.1786 15.315 10 14.5675V12.9C10 12.2675 10.2098 11.726 10.6295 11.2756C11.0491 10.8252 11.5536 10.6 12.1429 10.6H14.2857V7.15C14.2857 6.82417 14.3884 6.55104 14.5938 6.33063C14.7991 6.11021 15.0536 6 15.3571 6H19.6429C19.9464 6 20.2009 6.11021 20.4063 6.33063C20.6116 6.55104 20.7143 6.82417 20.7143 7.15V10.6H22.8571C23.4464 10.6 23.9509 10.8252 24.3705 11.2756C24.7902 11.726 25 12.2675 25 12.9V14.5675C23.8214 15.315 22.6161 15.9906 21.3839 16.5944C20.1518 17.1981 18.8571 17.5 17.5 17.5ZM12.1429 27.85C11.5536 27.85 11.0491 27.6248 10.6295 27.1744C10.2098 26.724 10 26.1825 10 25.55V17.155C11 17.8067 12.0312 18.3625 13.0938 18.8225C14.1563 19.2825 15.2679 19.5892 16.4286 19.7425V19.8C16.4286 20.1258 16.5313 20.399 16.7366 20.6194C16.942 20.8398 17.1964 20.95 17.5 20.95C17.8036 20.95 18.058 20.8398 18.2634 20.6194C18.4688 20.399 18.5714 20.1258 18.5714 19.8V19.7425C19.7321 19.5892 20.8438 19.2825 21.9063 18.8225C22.9688 18.3625 24 17.8067 25 17.155V25.55C25 26.1825 24.7902 26.724 24.3705 27.1744C23.9509 27.6248 23.4464 27.85 22.8571 27.85C22.8571 28.1758 22.7545 28.449 22.5491 28.6694C22.3438 28.8898 22.0893 29 21.7857 29C21.5 29 21.2991 28.861 21.183 28.5831C21.067 28.3052 20.9107 28.0608 20.7143 27.85H14.2857C14.2857 28.1758 14.183 28.449 13.9777 28.6694C13.7723 28.8898 13.5179 29 13.2143 29C12.9286 29 12.7277 28.861 12.6116 28.5831C12.4955 28.3052 12.3393 28.0608 12.1429 27.85Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
