import { Box } from "grommet";
import { colors } from "../assets/theme";
import { VerticalSeparator } from "./VerticalSeparator";
import React from "react";
import useMediaQuery from "../hooks/ueMediaQuery";
import { capitalizeFirstLetter } from "../utils";

const Tab = ({
  label,
  isActive,
  onClick,
  isFirst,
  isLast,
  disabled,
}: {
  label: string;
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  disabled: boolean;
  onClick: (label: string) => void;
}) => (
  <Box
    style={{
      flexGrow: 1,
      alignItems: "center",
      height: "100%",
      borderRadius: isFirst
        ? "15px 0 0 15px"
        : isLast
        ? "0 15px 15px 0"
        : "unset",
      cursor: "pointer",
    }}
    align="center"
    justify="center"
    background={
      disabled ? colors.plStone05 : isActive ? colors.plPurple : "unset"
    }
    color={isActive || disabled ? colors.plWhite : "unset"}
    width="100%"
    onClick={() => onClick(label)}
  >
    {capitalizeFirstLetter(label)}
  </Box>
);

export const TabSwitcher = ({
  tabs,
  onClick,
  isChangingTimeSpan,
}: {
  tabs?: { label: any; isActive: boolean }[];
  onClick: (label: any) => void;
  isChangingTimeSpan: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return (
    <Box
      direction="row"
      justify="between"
      border={{ size: "small" }}
      width={isDesktop ? "250px" : "200px"}
      height="30px"
      align="center"
      style={{
        zIndex: 3,
        borderRadius: "15px",
        fontSize: !isDesktop ? "12px" : "unset",
      }}
    >
      {tabs?.map((tab, i) => (
        <React.Fragment key={tab.label}>
          <Tab
            key={tab.label}
            disabled={isChangingTimeSpan}
            isFirst={i === 0}
            isLast={i === tabs.length - 1}
            isActive={tab.isActive}
            label={tab.label}
            onClick={() => (!isChangingTimeSpan ? onClick(tab.label) : null)}
          />
          {i !== tabs.length - 1 ? <VerticalSeparator margin="0" /> : null}
        </React.Fragment>
      ))}
    </Box>
  );
};
