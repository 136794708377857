import React, { ReactNode } from "react";
import { Box } from "grommet";
import { colors } from "../../../assets/theme";

export const SettingItem = ({ children }: { children: ReactNode }) => (
  <Box
    direction="row"
    margin={{ bottom: "20px" }}
    justify="between"
    border={{
      side: "bottom",
      style: "solid",
      size: "xsmall",
      color: colors.plStone05,
    }}
    pad={{ bottom: "5px" }}
    style={{ fontSize: "14px", maxHeight: "100%" }}
  >
    {children}
  </Box>
);
