import { Box } from "grommet";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createDatesInterval } from "../utils";
import {
  AppUserViewModel,
  DateInterval,
  OccurrenceViewModel,
  TeamViewModel,
} from "../types";
import { add, format, sub } from "date-fns";
import { DefaultView, IntervalDirection, Role } from "../enums";
import useMediaQuery from "../hooks/ueMediaQuery";
import "./TeamCard.css";
import { TeamCardBody } from "./TeamCardBody";
import { TeamWeekCardHeader } from "./TeamCardHeader";
import { LoadingScreen } from "./LoadingScreen";

const CardContainer = styled.div<{ isDesktop?: boolean }>`
  width: ${({ isDesktop }) => (!isDesktop ? "90%" : "720px")};
`;

export const TeamCard = ({
  team,
  allUsers,
  onChangeTimeInterval,
  isLoading,
  appUserId,
  showResetButton,
  onResetToCurrentTimeSpan,
  onSelectDate,
  filterValue,
  onFavouritesClick,
  showPinOnHover,
  favouriteAppUsers,
  isPinningUserId,
  activeTimeSpan,
}: {
  team: TeamViewModel;
  fromDate?: Date;
  toDate?: Date;
  onChangeTimeInterval: (
    teamId: string,
    newStartDate: Date,
    newToDate: Date
  ) => void;
  isLoading: boolean;
  isPinningUserId?: string;
  appUserId?: string;
  filterValue?: string;
  showResetButton: boolean;
  showPinOnHover: boolean;
  onResetToCurrentTimeSpan: () => void;
  onSelectDate: (
    date: string,
    occurrences: OccurrenceViewModel[],
    targetUser: AppUserViewModel
  ) => void;
  onFavouritesClick?: (appUser: AppUserViewModel) => void;
  favouriteAppUsers?: AppUserViewModel[];
  allUsers?: AppUserViewModel[];
  appUserRole: Role;
  activeTimeSpan?: DefaultView;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  const [intervalString, setIntervalString] = useState<string>("");
  const [datesInterval, setDatesInterval] = useState<DateInterval[]>([]);

  useEffect(() => {
    if (team.interval.fromDate && team.interval.toDate) {
      setDatesInterval(
        createDatesInterval(
          activeTimeSpan === DefaultView.week ? 7 : 14,
          team.interval.fromDate
        )
      );

      const intervalString = `${format(
        team.interval.fromDate,
        "do LLL"
      )} - ${format(team.interval.toDate, "do LLL")}`;

      setIntervalString(intervalString);
    }
  }, [team.interval.fromDate, team.interval.toDate]);

  const onChangeInterval = (direction: IntervalDirection) => {
    if (team.interval.fromDate && team.interval.toDate) {
      if (direction === IntervalDirection.next) {
        const newStartDate = add(team.interval.toDate, { days: 1 });
        const newToDate = add(newStartDate, {
          days: activeTimeSpan === DefaultView.week ? 6 : 13,
        });
        onChangeTimeInterval(team.id, newStartDate, newToDate);
      } else {
        const newStartDate = sub(team.interval.fromDate, {
          days: activeTimeSpan === DefaultView.week ? 7 : 14,
        });
        const newToDate = sub(team.interval.fromDate, { days: 1 });
        onChangeTimeInterval(team.id, newStartDate, newToDate);
      }
    }
  };

  if (!allUsers) {
    return <LoadingScreen />;
  }

  return (
    <Box
      direction="column"
      align="center"
      key={team.id}
      width="100%"
      data-testid={`dashboard-body-${team.name}`}
    >
      <CardContainer isDesktop={isDesktop}>
        <TeamWeekCardHeader
          showResetButton={showResetButton}
          onResetToCurrentTimeSpan={onResetToCurrentTimeSpan}
          isLoading={isLoading}
          intervalString={intervalString}
          team={team}
          onFetchPreviousWeek={() =>
            onChangeInterval(IntervalDirection.previous)
          }
          onFetchNextWeek={() => onChangeInterval(IntervalDirection.next)}
        />
        <TeamCardBody
          allUsers={allUsers}
          showPinOnHover={showPinOnHover}
          filterValue={filterValue}
          activeTimeSpan={activeTimeSpan}
          isPinningUserId={isPinningUserId}
          onFavouritesClick={onFavouritesClick}
          favouriteAppUsers={favouriteAppUsers}
          appUserId={appUserId}
          isLoading={isLoading}
          datesInterval={datesInterval}
          team={team}
          onSelectDate={onSelectDate}
        />
      </CardContainer>
    </Box>
  );
};
