import axios from "axios";
import { AppUser, AppUserViewModel } from "../types";

export default class AppUserService {
  getCurrentUser = async () => {
    return await axios.get(`/app-users/current`).then(async (res) => {
      if (res.data) {
        const appUserSettingsId = res.data.appUserSettingsId;

        const userSettings = await axios
          .get(`/app-user-settings/${appUserSettingsId}`)
          .then((res) => res.data);

        return {
          ...res.data,
          name: res.data.name.replace(/[.]/g, " "),
          appUserSettings: userSettings,
        };
      }
    });
  };

  getAllUsers = async () => {
    return await axios.get(`/app-users`).then(async (res) => {
      if (res.data) {
        const users = res.data
          .map((user: AppUserViewModel) => ({
            ...user,
            name: user.name.replace(/[.]/g, " ").replace(/^(ext)/gi, ""),
            managerOf: res.data
              .filter((u: AppUserViewModel) => u.managerId === user.id)
              .map((us: AppUserViewModel) => us.id),
          }))
          .filter((user: AppUserViewModel) => user.plasActive && !user.hidden);

        return users;
      }
    });
  };

  getUserById = async (id: string) => {
    return await axios.get(`/app-users/${id}`).then(async (res) => {
      if (res.data) {
        const appUserSettingsId = res.data.appUserSettingsId;

        const userSettings = await axios
          .get(`/app-user-settings/${appUserSettingsId}`)
          .then((res) => res.data);

        return {
          ...res.data,
          name: res.data.name.replace(/[.]/g, " "),
          appUserSettings: userSettings,
        };
      }
    });
  };

  updateAppUserDelegates = async (
    id: string,
    payload: { delegatesTo: string[] }
  ) => {
    console.log("in service", id, payload);
    return await axios
      .patch(`/app-users/${id}`, payload)
      .then(async (res) => res.data);
  };
}
