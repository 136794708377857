import styled from "styled-components";
import { colors } from "../assets/theme";
import { Box } from "grommet";

const StyledBox = styled(Box)<{ margin?: string }>`
  background-color: ${() => colors.plGrey02};
  width: 1px;
`;

export const VerticalSeparator = ({ margin }: { margin?: string }) => (
  <StyledBox margin={margin} />
);
