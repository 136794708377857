import styled from "styled-components";
import { Accordion, AccordionPanel, Box, Header, Layer, Text } from "grommet";
import useMediaQuery from "../hooks/ueMediaQuery";
import React from "react";
import { MobileCloseModalButton } from "./MobileCloseModalButton";
import { colors } from "../assets/theme";
import { faqData } from "../assets/faq";

const StyledLayer = styled(Layer)<{ isDesktop?: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? "650px" : null)};
  max-height: 700px;
  height: ${({ isDesktop }) => (isDesktop ? "unset" : "100%")};
`;

const ScrollableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 24px;
  span {
    text-align: center;
  }
`;

export const FAQ = ({ onClose }: { onClose: () => void }) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return (
    <StyledLayer onEsc={onClose} onClickOutside={onClose} isDesktop={isDesktop}>
      <MobileCloseModalButton topRight={true} onClick={onClose} />
      <Header
        margin="20px"
        alignSelf="start"
        style={{ color: colors.plPurple, fontSize: "22px" }}
      >
        Frequently Asked Questions
      </Header>
      <ScrollableContainer>
        <Accordion flex="grow" animate>
          {faqData.map((faq) => (
            <AccordionPanel
              style={{ color: colors.plDarkPurple }}
              label={faq.label}
              key={faq.label}
            >
              <Box pad="medium" background={colors.plPurple01}>
                <span style={{ textAlign: "left" }}>{faq.body}</span>
              </Box>
            </AccordionPanel>
          ))}
        </Accordion>
      </ScrollableContainer>
    </StyledLayer>
  );
};
