import React, { BaseSyntheticEvent, useState } from "react";
import "./Toggle.css";

type ToggleProps = {
  id: string;
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  optionLabels: string;
  small: boolean;
  disabled?: boolean;
};

export const Toggle = ({
  id,
  name,
  checked,
  onChange,
  optionLabels,
  small,
  disabled,
}: ToggleProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  return (
    <div className={"toggle-switch" + (small ? " small-switch" : "")}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          onChange(e.target.checked);
        }}
        disabled={disabled}
      />
      {id ? (
        <label className="toggle-switch-label" htmlFor={id}>
          <span
            className={
              disabled
                ? "toggle-switch-inner toggle-switch-disabled"
                : "toggle-switch-inner"
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
          />
          <span
            className={
              disabled
                ? "toggle-switch-switch toggle-switch-disabled"
                : "toggle-switch-switch"
            }
          />
        </label>
      ) : null}
    </div>
  );
};

// Set optionLabels for rendering.
Toggle.defaultProps = {
  optionLabels: ["Yes", "No"],
};
