import { Box, Spinner } from "grommet";
import React from "react";

export const Loading = () => (
  <Box
    direction="row"
    width="100%"
    justify="center"
    align="center"
    pad={{ vertical: "5px" }}
  >
    <Spinner />
  </Box>
);
