import React, { ReactElement } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { Logout } from "../pages/auth/Logout";
import { Dashboard } from "../pages/Dashboard";
import { Calendar } from "../pages/Calendar";
import { Teams } from "../pages/Teams";
import { Layout } from "../pages/layout/Layout";
import { ErrorPage } from "../pages/ErrorPage";
import { useAuth } from "oidc-react";
import { OfficeToday } from "../pages/OfficeToday";

const authRoutes: { path: string; element: ReactElement<any, any> }[] = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
  {
    path: "/teams",
    element: <Teams />,
  },
];

export const AllRoutes = () => {
  const auth = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {auth && !!auth.userData ? (
          <React.Fragment>
            {authRoutes.map(({ path, element }, key) => (
              <Route
                key={key}
                path={path}
                element={<Layout>{element}</Layout>}
              />
            ))}
            <Route path="/view">
              <Route path="office_today/:id" element={<OfficeToday />} />
            </Route>
          </React.Fragment>
        ) : null}
        <Route path="/logout" element={<Logout />} />

        <Route element={<ErrorPage error="404" />} />
        {/* TODO: 500 page */}
      </Routes>
    </BrowserRouter>
  );
};
