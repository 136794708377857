import { Box, Text } from "grommet";
import { Remote35 } from "./svgs/Remote/Remote35";
import { Leave35 } from "./svgs/Leave/Leave35";
import { Office35 } from "./svgs/Office/Office35";
import React from "react";

export const IconLegend = () => (
  <Box direction="row">
    <Box
      width="60px"
      direction="column"
      align="center"
      margin={{ horizontal: "small" }}
    >
      <Office35 />
      <Text style={{ textAlign: "center" }} margin={{ top: "xxsmall" }}>
        Office
      </Text>
    </Box>
    <Box
      width="60px"
      direction="column"
      align="center"
      margin={{ horizontal: "small" }}
    >
      <Remote35 />
      <Text style={{ textAlign: "center" }} margin={{ top: "xxsmall" }}>
        Remote
      </Text>
    </Box>
    <Box
      width="60px"
      direction="column"
      align="center"
      margin={{ left: "small" }}
    >
      <Leave35 plainBox />
      <Text style={{ textAlign: "center" }} margin={{ top: "xxsmall" }}>
        Leave
      </Text>
    </Box>
  </Box>
);
