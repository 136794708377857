import { Box } from "grommet";
import { DotPulse } from "./DotPulse/DotPulse";
import React from "react";
import styled from "styled-components";

const PlaceholderUpcomingEventsContainer = styled(Box)`
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  background-color: white;
  padding: 15px;
  margin: 24px 10px 10px;
  width: 450px;
  height: 750px;
`;

export const UpcomingEventsPlaceholder = () => {
  return (
    <PlaceholderUpcomingEventsContainer>
      <Box pad="50px" justify="center" alignContent="center">
        <DotPulse />
      </Box>
    </PlaceholderUpcomingEventsContainer>
  );
};
