import styled from "styled-components";
import { colors } from "../../assets/theme";
import { Card } from "grommet";

export const SummaryLabel = styled.span`
  display: flex;
  gap: 8px;
  flex-direction: column;
  color: ${() => colors.plDarkPurple06};
  padding: 30px 30px 20px;
  line-height: normal;
  position: sticky;
  top: 0;
  z-index: 3;
  align-self: flex-start;
  background-color: white;
`;

export const SummarySubtitleLabel = styled.span`
  display: flex;
  flex-direction: column;
  color: ${() => colors.plDarkPurple06};
  padding: 10px 10px 20px;
  line-height: normal;
  width: 100%;
  position: sticky;
  top: 102px;
  z-index: 3;
  align-self: flex-start;
  background-color: white;
`;


export const SummaryTitle = styled.span`
  font-weight: bold;
  font-size: 22px;
`;

export const SummarySubtitle = styled.span`
  font-size: 16px;
`;

export const SummaryCard = styled(Card)`
  background: ${() => colors.plWhite};
  width: 450px;
  margin-bottom: 16px;
  padding: 0 12px;

  display: block;
  max-height: 450px;
  overflow-y: auto;

`;
