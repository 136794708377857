import { useEffect, useMemo, useState } from "react";
import { TeamViewModel } from "../types";
import TeamService from "../services/teamService";

export const useGetTeam = (teamId?: string) => {
  const [team, setTeam] = useState<TeamViewModel>({} as TeamViewModel);
  const [isFetchingTeam, setIsFetchingTeam] = useState<boolean>(false);

  const teamService = useMemo(() => new TeamService(), []);

  const fetchTeam = async (teamId: string) => {
    setIsFetchingTeam(true);
    const team = await teamService.getTeamById({
      teamId: teamId,
    });
    setTeam(team);
    setIsFetchingTeam(false);
  };
  useEffect(() => {
    if (teamId) {
      fetchTeam(teamId);
    }
  }, [teamId]);

  const refetchTeam = (teamId: string) => {
    fetchTeam(teamId);
  };

  return { team, refetchTeam, isFetchingTeam };
};
