import AppUserSettingsService from "../services/appUserSettingsService";
import { useMemo, useState } from "react";
import { UpdateSettingsPayload } from "../types";

export const useMutateAppUserSettings = () => {
  const [isMutatingAppUserSettings, setIsMutatingAppUserSettings] =
    useState<boolean>(false);

  const appUserSettingsService = useMemo(
    () => new AppUserSettingsService(),
    []
  );

  const mutateAppUserSettings = async (
    newSettings: Partial<UpdateSettingsPayload>
  ) => {
    setIsMutatingAppUserSettings(true);
    return await appUserSettingsService
      .updateAppUserSettings(newSettings)
      .then((data) => {
        setIsMutatingAppUserSettings(false);
        return data;
      })
      .catch((err) => {
        setIsMutatingAppUserSettings(false);
        return err;
      });
  };

  return { mutateAppUserSettings, isMutatingAppUserSettings };
};
