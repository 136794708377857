import { AppUserSettings } from "../../types";
import {
  DashboardSettingsOptions,
  DefaultOffice,
  DefaultView,
  OfficeSettingsOptions,
} from "../../enums";
import { Box, Select } from "grommet";
import { capitalizeFirstLetter } from "../../utils";
import React from "react";
import { SettingItem } from "./shared/SettingItem";

export const OfficeSettings = ({
  settings,
  onUpdate,
}: {
  settings: AppUserSettings;
  onUpdate: (payload: Pick<AppUserSettings, DashboardSettingsOptions>) => void;
}) => {
  const onChange = async (officeSetting: OfficeSettingsOptions, value: any) => {
    const settingsCopy = settings;

    settingsCopy[officeSetting] = value;

    await onUpdate(settingsCopy);
  };

  return (
    <>
      <SettingItem>
        <Box direction="column">
          <Box>Default Office</Box>
        </Box>
        <Select
          style={{ height: "20px", width: "150px" }}
          size="small"
          options={Object.values(DefaultOffice).map((defaultOffice) =>
            capitalizeFirstLetter(defaultOffice)
          )}
          value={capitalizeFirstLetter(
            DefaultOffice[
              settings.defaultOffice as unknown as keyof typeof DefaultOffice
            ]
          )}
          onChange={(event) =>
            onChange(
              OfficeSettingsOptions.defaultOffice,
              event.target.value.replace(/\s/g, "").toLowerCase()
            )
          }
        />
      </SettingItem>
    </>
  );
};
