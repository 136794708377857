import { Box } from "grommet";
import { colors } from "../assets/theme";
import styled from "styled-components";

export const StyledBox = styled(Box)<{ margin?: string }>`
  width: 100%;
  background: ${() => colors.plGrey02};
  height: 1px;
  margin: ${({ margin }) => `${margin ?? "20px"} 0`};
`;

export const HorizontalSeparator = ({ margin }: { margin?: string }) => (
    <Box pad="0 70px">
        <StyledBox margin={margin} />
    </Box>
);
