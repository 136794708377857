import { Anchor, Box } from "grommet";
import { Previous } from "./svgs/Previous";
import { Next } from "./svgs/Next";
import React from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { IntervalDirection } from "../enums";
import useMediaQuery from "../hooks/ueMediaQuery";

const SeasonLabel = styled(Box)<{ isDesktop: boolean }>`
  display: flex;
  color: ${() => colors.plDarkPurple06};
  justify-content: start;
  max-width: 250px;
  font-size: ${({ isDesktop }) => (isDesktop ? "18px;" : "15px")};
`;

export const Switch = ({
  label,
  onFetchPrevious,
  onFetchNext,
  isLoading,
  showResetButton,
  onResetToCurrentSeason,
}: {
  label: string;
  isLoading: boolean;
  onFetchPrevious: (intervalDirection: IntervalDirection) => void;
  onFetchNext: (intervalDirection: IntervalDirection) => void;
  showResetButton: boolean;
  onResetToCurrentSeason: () => void;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return (
    <Box direction="row" align="center" justify="end">
      <SeasonLabel isDesktop={isDesktop} margin={{ right: "small" }}>
        {label}
      </SeasonLabel>
      {showResetButton ? (
        <Anchor margin={{ right: "medium" }} onClick={onResetToCurrentSeason}>
          (today)
        </Anchor>
      ) : null}
      <Box direction="row">
        <Previous
          isLoading={isLoading}
          onClick={() =>
            !isLoading ? onFetchPrevious(IntervalDirection.previous) : null
          }
        />
        <Next
          isLoading={isLoading}
          onClick={() =>
            !isLoading ? onFetchNext(IntervalDirection.next) : null
          }
        />
      </Box>
    </Box>
  );
};
