import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Box, Calendar, Notification, Tip } from "grommet";
import {
  canManage,
  getIconForInteractiveBox,
  isToday,
  isWeekend,
} from "../utils";
import { getDayTooltip } from "./DayInfoTooltip";
import { colors } from "../assets/theme";
import { AppUserViewModel, CalendarData } from "../types";
import { DotPulse } from "./DotPulse/DotPulse";
import { Info } from "grommet-icons";
import useMediaQuery from "../hooks/ueMediaQuery";
import { format } from "date-fns";

const StyledCalendar = styled(Calendar)`
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  background-color: ${colors.plWhite};
  padding: 15px;
  margin: 10px;
  width: 260px;
  div[role="grid"] {
    height: max-content;
  }
  div[role="row"] {
    margin-bottom: 15px;
    height: 20px;
    div {
      width: 20px;
      text-align: center;
    }
    div[role="gridcell"] {
      height: 20px;
    }
  }
`;

const CalendarHeading = styled.div`
  font-size: 16px;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-weight: bold;
  color: ${() => colors.plDarkPurple06};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrentMonthDot = styled.div`
  border-radius: 5px;
  width: 10px;
  height: 10px;
  background-color: ${() => colors.plGreen};
  margin: 0 10px;
`;

export const overlay20 = (color: string) => css`
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: ${() => color};
  }
`;

const todayBorder = () => css`
  border: ${() => `2px solid ${colors.plGreen}`};
  border-radius: 4px;
`;

const CalendarDaySmall = styled.div<{
  greyOverlay?: boolean;
  greenBorder?: boolean;
}>`
  position: relative;
  font-family: Premier League, serif;
  font-size: 14px;
  border: none !important;
  line-height: 1.375;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  opacity: 1;
  border-radius: 100px;
  ${({ greyOverlay }) => (greyOverlay ? overlay20(colors.weekendGrey) : null)}
  ${({ greenBorder }) => (greenBorder ? todayBorder : null)}
  > * {
    max-width: 35px;
    max-height: 35px;
  }
`;

const PlaceholderCalendarContainer = styled.div`
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  background-color: white;
  padding: 15px;
  margin: 10px;
  width: 260px;
  height: 260px;
`;

export const PlaceholderCalendar = ({
  isCurrentMonth,
  month,
}: {
  isCurrentMonth: boolean;
  month: string;
}) => (
  <PlaceholderCalendarContainer>
    <CalendarHeading>
      {isCurrentMonth ? <CurrentMonthDot /> : null}
      {month}
    </CalendarHeading>
    <Box pad="50px" justify="center" alignContent="center">
      <DotPulse />
    </Box>
  </PlaceholderCalendarContainer>
);

export const EventCalendar = ({
  occurrences,
  onSelectDate,
  appUser,
  selectedAppUser,
  isLoading,
  allUsers,
}: {
  occurrences: CalendarData;
  onSelectDate: (date: string | string[]) => void;
  appUser: AppUserViewModel;
  selectedAppUser: AppUserViewModel;
  allUsers: AppUserViewModel[];
  isLoading: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  const [showManagerRightsToast, setShowManagerRightsToast] =
    useState<boolean>();

  useEffect(() => {
    setShowManagerRightsToast(false);
  }, [selectedAppUser.id]);

  const getTooltip = (calendarDay: number) => {
    const day = occurrences.days.find((d) => d.number === calendarDay);
    if (day) {
      return getDayTooltip(day, selectedAppUser);
    }
  };

  const isCurrentMonth = (month: string) =>
    new Date(month).getMonth() === new Date().getMonth() &&
    new Date(month).getFullYear() === new Date().getFullYear();

  return (
    <>
      {isLoading ? (
        <PlaceholderCalendar
          isCurrentMonth={isCurrentMonth(occurrences.month)}
          month={occurrences.month}
        />
      ) : (
        <StyledCalendar
          daysOfWeek
          size="small"
          firstDayOfWeek={1}
          showAdjacentDays={false}
          // below we need to specify "day 1" of each month for the calendar date
          // passing only "new Date(<month> <year>)" is an invalid date on Firefox
          date={new Date("1 " + occurrences.month).toISOString()}
          onSelect={async (date) => {
            //@ts-ignore
            const ukDate = format(new Date(date), "P");

            // check if AppUser has rights to log data for the selectedAppUser
            if (canManage(appUser, selectedAppUser, allUsers)) {
              setShowManagerRightsToast(false);
              onSelectDate(ukDate);
              return;
            }
            setShowManagerRightsToast(true);
          }}
          header={() => (
            <CalendarHeading>
              {isCurrentMonth(occurrences.month) ? <CurrentMonthDot /> : null}
              {occurrences.month}
            </CalendarHeading>
          )}
          children={({ day, date }) => {
            const getEventIcon = (day: number) => {
              return occurrences.days.map((occurrence) => {
                if (day === occurrence.number) {
                  return getIconForInteractiveBox(occurrence, "20", true, true);
                }
                return [];
              });
            };
            return isDesktop ? (
              <Tip content={getTooltip(day)}>
                <CalendarDaySmall
                  greenBorder={isToday(date)}
                  greyOverlay={isWeekend(date)}
                >
                  {getEventIcon(day)}
                </CalendarDaySmall>
              </Tip>
            ) : (
              <CalendarDaySmall
                greenBorder={isToday(date)}
                greyOverlay={isWeekend(date)}
              >
                {getEventIcon(day)}
              </CalendarDaySmall>
            );
          }}
        />
      )}
      {showManagerRightsToast ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          title={`You cannot create or amend events for ${selectedAppUser.name}.`}
          onClose={() => setShowManagerRightsToast(false)}
        />
      ) : null}
    </>
  );
};
