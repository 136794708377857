import { LeaveType, OccurrenceType } from "../enums";
import {
  capitalizeFirstLetter,
  getOrdinalSuffixOf,
  monthsArray,
  weekdaysArray,
} from "../utils";
import React, { ReactNode } from "react";
import { Box, Text, Tip } from "grommet";
import { colors } from "../assets/theme";
import styled from "styled-components";
import { clubLogos } from "../assets/club_logos/48";
import { Leave35 } from "./svgs/Leave/Leave35";
import { Remote35 } from "./svgs/Remote/Remote35";
import { Office35 } from "./svgs/Office/Office35";
import { AppUserViewModel, Day, OccurrenceViewModel } from "../types";
import { AvatarHeader } from "./AvatarHeader";
import { Office20 } from "./svgs/Office/Office20";
import { Leave20 } from "./svgs/Leave/Leave20";
import { Remote20 } from "./svgs/Remote/Remote20";
import useMediaQuery from "../hooks/ueMediaQuery";
import { Stroller20 } from "./svgs/Stroller/Stroller20";
import { Stroller35 } from "./svgs/Stroller/Stroller35";
import { Sick35 } from "./svgs/Sick/Sick35";
import { Sick20 } from "./svgs/Sick/Sick20";
import { Travel35 } from "./svgs/Travel/Travel35";
import { Travel20 } from "./svgs/Travel/Travel20";

export const DurationBox = styled(Box)<{ isActive?: boolean; height?: string }>`
  border: ${() => `2px solid ${colors.plPurple06}`};
  color: ${() => colors.plPurple06};
  border-radius: 5px;
  font-size: 14px;
  font-weight: bolder;
  width: 45px;
  height: ${({ height }) => height ?? "25px"};
  text-align: center;
  padding: 0 5px;
  justify-content: center;
  background-color: ${({ isActive }) =>
    !isActive ? colors.plWhite : colors.plGrey02};
  line-height: 1;
`;

const TooltipBox = styled(Box)<{ n_occs: number }>`
  height: ${({ n_occs }) => (n_occs === 2 ? "290px" : "220px")};
  display: block;
  overflow-x: clip;
  overflow-y: hidden;
`;
export const getIconForOccurrenceType = (
  occurrence?: OccurrenceViewModel | null,
  iconSize?: string
) => {
  const occurrenceType = occurrence?.type;
  const occurrenceDescription = occurrence?.description;

  switch (true) {
    case !occurrenceType:
      return <></>;
    case occurrenceType === OccurrenceType.leave:
    case occurrenceType === OccurrenceType.holiday:
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Leave20
              leaveType={occurrenceDescription as LeaveType}
              positionAbsolute
            />
          ) : (
            <Leave35
              leaveType={occurrenceDescription as LeaveType}
              positionAbsolute
            />
          )}
        </Box>
      );
    case occurrenceType === OccurrenceType.brunel:
    case occurrenceType === OccurrenceType.londonbrunel.replace(/\s/g, ""):
    case occurrenceType === OccurrenceType.newyork.replace(/\s/g, ""):
    case occurrenceType === OccurrenceType.singapore:
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Office20 positionAbsolute />
          ) : (
            <Office35 positionAbsolute />
          )}
        </Box>
      );
    case occurrenceType === OccurrenceType.remote:
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Remote20 positionAbsolute />
          ) : (
            <Remote35 positionAbsolute />
          )}
        </Box>
      );
    case occurrenceType === OccurrenceType.parentalleave.replace(/\s/g, ""):
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Stroller20 positionAbsolute />
          ) : (
            <Stroller35 positionAbsolute />
          )}
        </Box>
      );
    case occurrenceType === OccurrenceType.sickleave.replace(/\s/g, ""):
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Sick20 positionAbsolute />
          ) : (
            <Sick35 positionAbsolute />
          )}
        </Box>
      );
    case occurrenceType === OccurrenceType.travel:
      return (
        <Box
          width="40px"
          align="center"
          justify="center"
          height="40px"
          margin={{ left: "xsmall", right: "xsmall" }}
        >
          {iconSize === "20" ? (
            <Travel20 positionAbsolute />
          ) : (
            <Travel35 positionAbsolute />
          )}
        </Box>
      );
    default:
      return (
        <Box margin={{ left: "xsmall", right: "xsmall" }} align="center">
          <img
            // @ts-ignore
            src={clubLogos[occurrenceType?.replace(/\s/g, "")]}
            width={`${iconSize ?? "30"}px`}
            alt={occurrenceType?.toString()}
          />
        </Box>
      );
  }
};

export const getDayTooltip = (day: Day, appUser: AppUserViewModel) => {
  const getEventsString = (occs: OccurrenceViewModel[]) => {
    return occs?.map((occurrence, i) => (
      <Box
        key={`${occurrence.type}${i}`}
        style={{ minWidth: "350px" }}
        pad={{ horizontal: "medium", vertical: "small" }}
        background={colors.plWhite}
      >
        <Box direction="row" align="center">
          <DurationBox>{occurrence.duration?.toUpperCase()}</DurationBox>
          <Box direction="row" align="center">
            <Box margin="0 5px" width="40px" align="center">
              {getIconForOccurrenceType(occurrence)}
            </Box>
            <Box direction="column">
              <Text
                margin={{ left: "5px", right: "5px" }}
                color={colors.plDarkPurple}
              >
                {occurrence?.type
                  ? `${capitalizeFirstLetter(
                      OccurrenceType[
                        occurrence.type as keyof typeof OccurrenceType
                      ]
                    )}`
                  : "n/a"}
              </Text>
              {occurrence.description && (
                <Text
                  margin={{ left: "5px", right: "5px" }}
                  size="small"
                  color={colors.plDarkPurple}
                >
                  {occurrence.description}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    ));
  };

  const month = monthsArray[new Date(day.date).getMonth()];
  const year = new Date(day.date).getFullYear();
  const weekDay = weekdaysArray[new Date(day.date).getDay()];

  const dateString = `${weekDay} ${getOrdinalSuffixOf(
    day.number
  )} ${month} ${year}`;

  return (
    <TooltipBox n_occs={day.occurrences.length}>
      <Box round="xsmall">
        <Box
          direction="row"
          flex="grow"
          pad="medium"
          background={colors.plStone05}
        >
          <Text weight="bold" color={colors.plBlack}>
            {dateString}
          </Text>
        </Box>
        <Box>{getEventsString(day.occurrences)}</Box>
        <Box pad="small" margin={"0 0 0 10px"}>
          <AvatarHeader appUser={appUser} />
        </Box>
      </Box>
    </TooltipBox>
  );
};

export const DayInfoTooltip = ({
  day,
  children,
  appUser,
}: {
  day: Day;
  children: ReactNode;
  appUser: AppUserViewModel;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  return isDesktop ? (
    <Tip content={getDayTooltip(day, appUser)}>{children}</Tip>
  ) : (
    <>{children}</>
  );
};
