import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { TeamCardBody } from "../components/TeamCardBody";
import { Box, Card, Select } from "grommet";
import { Switch } from "../components/Switch";
import { Down } from "grommet-icons";
import {
  AppUserViewModel,
  DateInterval,
  OccurrenceViewModel,
  Team,
  TeamViewModel,
} from "../types";
import {
  capitalizeFirstLetter,
  createDatesInterval,
  generateDays,
  getAppUsersWithDays,
  getDaysInMonth,
  sortWithUsersEntityFirst,
} from "../utils";
import OccurrenceService from "../services/occurrenceService";
import { IntervalDirection } from "../enums";
import { add, sub } from "date-fns";
import { Loading } from "../components/Loading";
import { PageHeader } from "../components/PageHeader";
import { AvatarHeader } from "../components/AvatarHeader";
import { HorizontalSeparator } from "../components/HorizontalSeparator";
import { EventModal } from "../components/EventModal";
import { ErrorPage } from "./ErrorPage";
import useMediaQuery from "../hooks/ueMediaQuery";
import { IconLegend } from "../components/IconLegend";
import { LoadingScreen } from "../components/LoadingScreen";
import { useGetAppUser } from "../hooks/useGetAppUser";
import { useFetchDepartmentUsers } from "../hooks/useFetchDepartmentUsers";
import { useGetDepartment } from "../hooks/useGetDepartment";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => colors.plStone02};
  z-index: 1;
  min-height: 100vh;
  width: 1350px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  padding: 12px 12px 20px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);
  overflow-y: auto;
  background-color: ${() => colors.plWhite};
`;

const StyledSelect = styled(Select)<{ isDesktop: boolean }>`
  color: ${() => colors.plDarkPurple06};
  font-size: ${({ isDesktop }) => (isDesktop ? "18px;" : "15px")};
`;

export const Teams = () => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  const occurrenceService = useMemo(() => new OccurrenceService(), []);

  const { appUser } = useGetAppUser();
  const { allUsers } = useFetchDepartmentUsers();
  const { department } = useGetDepartment(appUser?.departmentId);

  const [teams, setTeams] = useState<TeamViewModel[]>([]);
  const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(false);

  const [datesInterval, setDatesInterval] = useState<DateInterval[]>([]);
  const [teamNamesOptions, setTeamNamesOptions] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team>({} as TeamViewModel);

  const [currentMonth, setCurrentMonth] = useState<string>("");

  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();

  const [targetUser, setTargetUser] = useState<AppUserViewModel>();
  const [selectedDate, setSelectedDate] = useState<string | string[]>("");
  const [selectedDateOccurrence, setSelectedDateOccurrence] = useState<
    OccurrenceViewModel[]
  >([]);

  const firstDayInMonth = (date?: Date) =>
    new Date(
      (date ?? new Date()).getFullYear(),
      (date ?? new Date()).getMonth(),
      1
    );

  const lastDayInMonth = (date?: Date) =>
    new Date(
      (date ?? new Date()).getFullYear(),
      (date ?? new Date()).getMonth() + 1,
      0
    );

  const fetchOccurrencesForTeams = async (teams: TeamViewModel[]) => {
    if (fromDate && toDate && appUser?.departmentId) {
      setIsFetchingTeams(true);
      const occurrences = await occurrenceService.getOccurrencesByDepartmentId({
        departmentId: appUser?.departmentId,
        from_date: fromDate,
        to_date: toDate,
      });

      const currentYear = fromDate.getFullYear();
      const currentMonth = fromDate.getMonth() + 1;
      const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

      const interval = createDatesInterval(daysInCurrentMonth, fromDate);
      setDatesInterval(interval);
      setCurrentMonth(monthNames[fromDate.getMonth()]);

      const teamsWithOccurrences = teams.map((team) => {
        if (team.appUsers.length > 0 && appUser?.id) {
          const usersWithDays = getAppUsersWithDays(
            appUser?.id,
            team.appUsers,
            interval,
            occurrences
          );

          team = {
            ...team,
            // sort with appUser on top
            appUsers: sortWithUsersEntityFirst(appUser?.id, usersWithDays),
          };

          return {
            ...team,
            appUsers: team.appUsers.map((user: AppUserViewModel) => {
              return {
                ...user,
              };
            }),
          };
        }
        return team;
      });

      setTeams(sortWithUsersEntityFirst(appUser?.teamId, teamsWithOccurrences));
    }
  };

  useEffect(() => {
    setFromDate(firstDayInMonth());
    setToDate(lastDayInMonth());
    setCurrentMonth(monthNames[new Date().getMonth()]);
  }, []);

  useEffect(() => {
    if (department && department.teams && appUser?.teamId) {
      setTeamNamesOptions(
        sortWithUsersEntityFirst(appUser?.teamId, department.teams).map(
          (team: Team) => team?.name
        )
      );

      fetchOccurrencesForTeams(department.teams);
    }
  }, [department, appUser, fromDate, toDate]);

  useEffect(() => {
    if (teams && teams?.length > 0) {
      const selectedTeamIndex = teams?.findIndex(
        (t) => t.id === selectedTeam?.id
      );
      const appUserTeamIndex = teams?.findIndex(
        (t) => t.id === appUser?.teamId
      );

      setSelectedTeam(
        selectedTeamIndex > -1
          ? teams[selectedTeamIndex]
          : appUserTeamIndex > -1
          ? teams[appUserTeamIndex]
          : teams[0]
      );

      setIsFetchingTeams(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  const onChangeTeam = (teamLabel: string) => {
    const team = teams?.find((team) => team.name === teamLabel);
    if (!team) {
      return;
    }
    setSelectedTeam(team);
  };

  const onChangeTimeInterval = async (
    fromDate: Date,
    direction?: IntervalDirection
  ) => {
    if (fromDate) {
      const newFromDate =
        direction === IntervalDirection.next
          ? add(fromDate, { months: 1 })
          : direction === IntervalDirection.previous
          ? sub(fromDate, { months: 1 })
          : fromDate;

      setFromDate(newFromDate);
      setToDate(lastDayInMonth(newFromDate));
      setCurrentMonth(monthNames[newFromDate.getMonth()]);
    }
  };

  const onSelectDate = (
    date: string,
    occurrences: OccurrenceViewModel[],
    targetUser: AppUserViewModel
  ) => {
    setSelectedDate(date);
    setTargetUser(targetUser);
    setSelectedDateOccurrence(occurrences);
  };

  const onSuccess = async () => {
    const targetUserTeam = teams.find((team) => team.id === targetUser?.teamId);

    if (fromDate && toDate && targetUser && targetUserTeam) {
      const refreshedOccurrences =
        await occurrenceService.getOccurrencesByDepartmentId({
          departmentId: targetUser?.id,
          from_date: fromDate,
          to_date: toDate,
        });

      const currentYear = fromDate.getFullYear();
      const currentMonth = fromDate.getMonth() + 1;
      const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

      const newTeams = teams.map((team) =>
        team.id === targetUserTeam.id
          ? {
              ...team,
              appUsers: team.appUsers.map((appUser) =>
                appUser.id === targetUser.id
                  ? {
                      ...appUser,
                      days: generateDays(
                        createDatesInterval(daysInCurrentMonth, fromDate),
                        refreshedOccurrences
                      ),
                    }
                  : appUser
              ),
            }
          : team
      );

      fetchOccurrencesForTeams(newTeams);
    }
  };

  const MonthSwitcher = () => (
    <Box>
      <Box direction="row" align="center" justify="between" pad="0 70px">
        {teams ? (
          <Box style={{ marginRight: "20px" }}>
            <StyledSelect
              isDesktop={isDesktop}
              width={"20px"}
              plain
              icon={<Down color={colors.plPurple} />}
              options={teamNamesOptions.map((teamName) => teamName)}
              value={capitalizeFirstLetter(selectedTeam.name)}
              onChange={({ option }) => onChangeTeam(option)}
            />
          </Box>
        ) : null}
        <Switch
          onResetToCurrentSeason={() => {
            if (fromDate && toDate) {
              onChangeTimeInterval(firstDayInMonth());
            }
          }}
          showResetButton={
            new Date().getMonth() !== new Date(fromDate as Date).getMonth()
          }
          isLoading={isFetchingTeams}
          label={`${currentMonth} ${new Date().getFullYear()}`}
          onFetchPrevious={(direction) => {
            if (fromDate) {
              onChangeTimeInterval(fromDate, direction);
            }
          }}
          onFetchNext={(direction) => {
            if (fromDate) {
              onChangeTimeInterval(fromDate, direction);
            }
          }}
        />
      </Box>
    </Box>
  );

  if (!appUser?.id) {
    return <LoadingScreen />;
  }

  if (!appUser?.id ?? !allUsers) {
    return <ErrorPage error="Cannot find selectedUserName." />;
  }

  return (
    <Container>
      {isDesktop ? (
        <>
          <PageHeader>
            <Box style={{ flexShrink: 0 }}>
              <AvatarHeader appUser={appUser} />
            </Box>

            <IconLegend />
          </PageHeader>
          <HorizontalSeparator />
        </>
      ) : null}
      <Box width="100%" height="50px">
        <MonthSwitcher />
      </Box>
      <HorizontalSeparator />

      <CardContainer>
        {selectedTeam && selectedTeam.appUsers ? (
          <TeamCardBody
            allUsers={allUsers}
            showPinOnHover={false}
            showingTheWholeMonth
            onSelectDate={onSelectDate}
            appUserId={appUser?.id}
            isLoading={isFetchingTeams}
            withSeparator
            team={selectedTeam?.id ? selectedTeam : ({} as TeamViewModel)}
            datesInterval={datesInterval}
          />
        ) : (
          <Loading />
        )}
      </CardContainer>

      {!!selectedDate && !!targetUser && (
        <EventModal
          appUser={appUser}
          targetUser={targetUser}
          date={selectedDate}
          selectedDateOccurrence={selectedDateOccurrence}
          onCloseModal={() => setSelectedDate("")}
          onSuccess={onSuccess}
        />
      )}
    </Container>
  );
};
