import env from "@beam-australia/react-env";
import { useAuth } from "oidc-react";
import axios from "axios";
import React from "react";
import { LoadingScreen } from "../components/LoadingScreen";

export const oidcConfig = {
  onSignIn: async (user: any) => {
    window.location.search = "";
  },
  authority: env("AUTH_URL"),
  clientId: env("IDENTITY_CLIENT_ID"),
  redirectUri: env("PUBLIC_URL"),
  responseType: "code",
  scope: env("SCOPE"),
};

export const AuthContext = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  if (auth && auth.isLoading) return <LoadingScreen />;

  if (auth && auth.userData && auth.userData.access_token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.userData.access_token.toString()}`;
  }

  return children;
};
