import { useQuery, keepPreviousData } from "@tanstack/react-query";
import AppUserAvatarService from "../services/appUserAvatarService";
import AppUserService from "../services/appUserService";

export const useGetAvatarByIdQUERY = (userId: string) => {
  const appUserAvatarService = new AppUserAvatarService();
  const appUserService = new AppUserService();

  const { data: user } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => appUserService.getUserById(userId),
    enabled: !!userId,
  });
  const appUserAvatarId = user?.appUserAvatarId;

  const { isPending, data, isError, error } = useQuery({
    queryKey: ["avatar", appUserAvatarId],
    queryFn: () => appUserAvatarService.getAvatarById(appUserAvatarId),
    staleTime: Infinity,
    gcTime: 1000 * 60 * 60 * 48, // 2 days
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    enabled: !!appUserAvatarId,
  });
  if (isError) {
    console.log(`Error: ${error.message}`);
  }
  return data;
};
