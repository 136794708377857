import { UpdateSettingsPayload } from "../types";
import axios from "axios";

export default class AppUserSettingsService {
  getAppUserSettings = async (appUserSettingsId?: string) => {
    return await axios
      .get(`/app-user-settings/${appUserSettingsId}`)
      .then((res) => res.data)
      .catch((err) => {
        throw new Error(err.response.data.error);
      });
  };

  updateAppUserSettings = async (payload: Partial<UpdateSettingsPayload>) => {
    return axios
      .post(`/app-user-settings/${payload.id}`, payload)
      .then((data) => data)
      .catch((err) => {
        throw new Error(err.response.data.error);
      });
  };
}
