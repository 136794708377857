import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { colors } from "../../../assets/theme";
import { Box } from "grommet";

export const Travel20 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="20px"
      height="20px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,10px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,10px)"
            : "unset",
        background: colors.plBlue1,
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" fill="#2B59FC" />
        <path
          d="M9.38776 6.05716H10.6123V4.74287H9.38776V6.05716ZM10 10C9.2245 10 8.4847 9.82752 7.78062 9.48252C7.07654 9.13752 6.38776 8.75145 5.71429 8.3243V7.37145C5.71429 7.01002 5.83419 6.70061 6.07399 6.44323C6.31378 6.18585 6.60205 6.05716 6.93878 6.05716H8.16327V4.08573C8.16327 3.89954 8.22195 3.74347 8.33929 3.61752C8.45664 3.49157 8.60205 3.42859 8.77552 3.42859H11.2245C11.398 3.42859 11.5434 3.49157 11.6607 3.61752C11.7781 3.74347 11.8367 3.89954 11.8367 4.08573V6.05716H13.0612C13.398 6.05716 13.6862 6.18585 13.926 6.44323C14.1658 6.70061 14.2857 7.01002 14.2857 7.37145V8.3243C13.6123 8.75145 12.9235 9.13752 12.2194 9.48252C11.5153 9.82752 10.7755 10 10 10ZM6.93878 15.9143C6.60205 15.9143 6.31378 15.7856 6.07399 15.5282C5.83419 15.2709 5.71429 14.9614 5.71429 14.6V9.80287C6.28572 10.1753 6.87501 10.4929 7.48215 10.7557C8.08929 11.0186 8.7245 11.1938 9.38776 11.2814V11.3143C9.38776 11.5005 9.44644 11.6566 9.56378 11.7825C9.68113 11.9085 9.82654 11.9714 10 11.9714C10.1735 11.9714 10.3189 11.9085 10.4362 11.7825C10.5536 11.6566 10.6123 11.5005 10.6123 11.3143V11.2814C11.2755 11.1938 11.9107 11.0186 12.5179 10.7557C13.125 10.4929 13.7143 10.1753 14.2857 9.80287V14.6C14.2857 14.9614 14.1658 15.2709 13.926 15.5282C13.6862 15.7856 13.398 15.9143 13.0612 15.9143C13.0612 16.1005 13.0026 16.2566 12.8852 16.3825C12.7679 16.5085 12.6225 16.5714 12.449 16.5714C12.2857 16.5714 12.1709 16.492 12.1046 16.3332C12.0383 16.1744 11.949 16.0348 11.8367 15.9143H8.16327C8.16327 16.1005 8.1046 16.2566 7.98725 16.3825C7.86991 16.5085 7.7245 16.5714 7.55103 16.5714C7.38776 16.5714 7.27297 16.492 7.20664 16.3332C7.14031 16.1744 7.05103 16.0348 6.93878 15.9143Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
