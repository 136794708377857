export const faqData = [
  {
    label: "What is Where Am I? ",
    body: "Where Am I is an application where staff and consultants can create events to indicate where they will be working on a given day. They can also create events for holidays, parental leave and sick leave.",
  },
  {
    label: "Will this replace our current HR solution (ADP)? ",
    body: "No, Where Am I compliments our current system and is seen as an additional tool for departments to easily track their teams. It is a business requirement to record all annual leave and sickness in ADP, so please ensure this is still completed. ",
  },
  {
    label: "Can I create, amend or delete events for other people? ",
    body: "You can only create, amend or delete events for other people you directly or indirectly manage as determined by the Staff Directory.",
  },
  {
    label:
      "My Line Manager has changed. Will my new Line Manager be able to create, amend or delete events for me? ",
    body: "Yes, if your Line Manager changes, your new Line Manager will be able to create, amend or delete events for you.",
  },
  {
    label:
      "What will happen to my events if I move team within the same department? ",
    body: "If you move team within the same department, your events will remain intact and your new team will now see the information.",
  },
  {
    label:
      "What will happen to my events if I move team in a different department? ",
    body: "If you move team to a different department, your events will remain intact and your new department will now see your information.",
  },
  {
    label: "Will new joiners automatically be given access to Where Am I? ",
    body: "New joiners joining a team that has opted to use Where Am I will be granted access as part of their onboarding process.",
  },
  {
    label:
      "Will new joiners be given a demo of Where Am I as part of their IT Induction? ",
    body: "New joiners joining a team that has opted to use Where Am I will be given a demo as part of their IT Induction.",
  },
  {
    label: "What will happen when a user's name changes? ",
    body: "If a user’s name changes, this will not affect their booked events.",
  },
  {
    label: "What will happen if a user's title changes? ",
    body: "If a user’s title changes, this will not affect their booked events.",
  },
  {
    label: "Can I receive daily email reports?",
    body: "Yes, you can select to receive daily and/or weekly email reports. To do so, please select which reports you would like to receive from the Notifications options (top right of the screen). Please note that email reports are switched off by default.",
  },
  {
    label:
      "Why are some people's events showing incorrectly in the email notification?",
    body: "The email notifications only show events that were booked by the time the report was generated. If a user has created events after this time then the email notification may appear incorrect for them.",
  },
  {
    label: "Who should I contact for support?",
    body: "Under your profile photo, there is a Support menu option. Select this to send your issue to the Service Desk.",
  },
];
