import styled from "styled-components";
import { Text } from "grommet";
import { colors } from "../assets/theme";

const StyledText = styled(Text)`
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  background-color: ${() => colors.plMagenta01};
  color: ${() => colors.plMagenta1};
`;

export const ErrorMessage = ({ error }: { error: string }) => {
  return <StyledText>{error}</StyledText>;
};
