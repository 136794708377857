export const AllClubs = [
  {
    clubKey: "arsenalfc",
    clubLabel: "arsenal fc",
    inPremierLeague: true,
  },
  {
    clubKey: "astonvillafc",
    clubLabel: "aston villa fc",
    inPremierLeague: true,
  },
  {
    clubKey: "afcbournemouth",
    clubLabel: "afc bournemouth",
    inPremierLeague: true,
  },
  {
    clubKey: "fulhamfc",
    clubLabel: "fulham fc",
    inPremierLeague: true,
  },
  {
    clubKey: "nottinghamforestfc",
    clubLabel: "nottingham forest fc",
    inPremierLeague: true,
  },
  {
    clubKey: "evertonfc",
    clubLabel: "everton fc",
    inPremierLeague: true,
  },
  {
    clubKey: "newcastleunited",
    clubLabel: "newcastle united",
    inPremierLeague: true,
  },
  {
    clubKey: "norwichfc",
    clubLabel: "norwich fc",
    inPremierLeague: false,
  },
  {
    clubKey: "brentfordfc",
    clubLabel: "brentford fc",
    inPremierLeague: true,
  },
  {
    clubKey: "leedsunitedfc",
    clubLabel: "leeds united fc",
    inPremierLeague: false,
  },
  {
    clubKey: "southamptonfc",
    clubLabel: "southampton fc",
    inPremierLeague: false,
  },
  {
    clubKey: "brightonandhovefc",
    clubLabel: "brighton and hove fc",
    inPremierLeague: true,
  },
  {
    clubKey: "leicestercityfc",
    clubLabel: "leicester city fc",
    inPremierLeague: false,
  },
  {
    clubKey: "tottenhamhotspurfc",
    clubLabel: "tottenham hotspur fc",
    inPremierLeague: true,
  },
  {
    clubKey: "burnleyfc",
    clubLabel: "burnley fc",
    inPremierLeague: true,
  },
  {
    clubKey: "liverpoolfc",
    clubLabel: "liverpool fc",
    inPremierLeague: true,
  },
  {
    clubKey: "watfordfc",
    clubLabel: "watford fc",
    inPremierLeague: false,
  },
  {
    clubKey: "chelseafc",
    clubLabel: "chelsea fc",
    inPremierLeague: true,
  },
  {
    clubKey: "manchestercity",
    clubLabel: "manchester city",
    inPremierLeague: true,
  },
  {
    clubKey: "westhamunited",
    clubLabel: "west ham united",
    inPremierLeague: true,
  },
  {
    clubKey: "crystalpalacefc",
    clubLabel: "crystal palace fc",
    inPremierLeague: true,
  },
  {
    clubKey: "manchesterunited",
    clubLabel: "manchester united",
    inPremierLeague: true,
  },
  {
    clubKey: "wolverhamptonfc",
    clubLabel: "wolverhampton fc",
    inPremierLeague: true,
  },
  {
    clubKey: "sheffieldunitedfc",
    clubLabel: "sheffield united fc",
    inPremierLeague: true,
  },
  {
    clubKey: "lutontownfc",
    clubLabel: "luton town fc",
    inPremierLeague: true,
  },
];
