import React from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { Box } from "grommet";
import { Day } from "../types";
import { OccurrenceType } from "../enums";
import { capitalizeFirstLetter, getIconForInteractiveBox } from "../utils";

const EventContainer = styled(Box)`
  display: flex;
  background-color: ${() => colors.plWhite};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;

export const IconBox = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 35px;
`;

export const OccurrenceLabel = styled(Box)`
  font-size: 20px;
  line-height: 23px;
  color: ${() => colors.plDarkPurple};
  width: 200px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 15px;
`;

const CalendarDay = styled.div`
  display: flex;
  flex-direction: column;
  border: ${() => `2px solid ${colors.plDarkGrey}`};
  border-radius: 4px;
  font-weight: 700;
  color: ${() => colors.plDarkPurple06};
  align-items: center;
  width: 42px;
  height: 42px;
  justify-content: center;
  line-height: 1;
  margin-right: 5px;
  flex-shrink: 0;
`;

const DayNumber = styled.span`
  font-size: 18px;
  text-transform: uppercase;
  color: ${() => colors.plDarkPurple06};
  font-weight: bold;
`;

const DayMonth = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  color: ${() => colors.plDarkPurple06};
  font-weight: bold;
`;

export const UpcomingOccurrences = ({
  month,
  day,
}: {
  month: string;
  day: Day;
}) => {
  const label = day.occurrences
    .map((occurrence) => {
      return capitalizeFirstLetter(
        OccurrenceType[occurrence.type as keyof typeof OccurrenceType]
      );
    })
    .join("/");
  return (
    <EventContainer direction="row">
      <Box
        style={{ position: "relative", paddingLeft: "10px" }}
        direction="row"
        align="center"
        flex="grow"
      >
        <IconBox>{getIconForInteractiveBox(day, "35", false, true)}</IconBox>
        <OccurrenceLabel margin={{ horizontal: "15px" }}>
          <span>{label}</span>
        </OccurrenceLabel>
      </Box>
      <CalendarDay>
        <DayNumber>{day.number}</DayNumber>
        <DayMonth>{month.substring(0, 3)}</DayMonth>
      </CalendarDay>
    </EventContainer>
  );
};
