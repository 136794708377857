import React from "react";
import { OccurrenceDuration } from "../../../enums";

export const Office35 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => (
  <svg
    style={{
      position: positionAbsolute ? "absolute" : "unset",
      clip:
        occurrenceDuration === OccurrenceDuration?.am
          ? "rect(auto,17.5px,auto,auto)"
          : occurrenceDuration === OccurrenceDuration?.pm
          ? "rect(auto,auto,auto,17.5px)"
          : "unset",
    }}
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="35" height="35" fill="#9747FF" />
  </svg>
);
