import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { Box } from "grommet";

export const Sick20 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="20px"
      height="20px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,10px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,10px)"
            : "unset",
        background: "yellowgreen",
      }}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 4.9C11.9333 4.9 11.3333 4.27 11.3333 3.5C11.3333 2.73 12.6667 0.7 12.6667 0.7C12.6667 0.7 14 2.73 14 3.5C14 4.27 13.4 4.9 12.6667 4.9ZM10.3333 3.5C10.3333 2.989 10.6067 2.303 10.9467 1.638C9.78667 0.616 8.29333 0 6.66 0C2.98 0 0 3.136 0 7C0 10.864 2.98 14 6.66 14C10.3467 14 13.3333 10.864 13.3333 7C13.3333 6.615 13.2933 6.237 13.24 5.866C13.0533 5.915 12.8667 5.95 12.6667 5.95C11.38 5.95 10.3333 4.851 10.3333 3.5ZM9.08 3.766L9.78667 4.508L9.08 5.25L9.78667 5.992L9.08 6.734L7.66667 5.25L9.08 3.766ZM3.54667 4.508L4.25333 3.766L5.66667 5.25L4.25333 6.734L3.54667 5.992L4.25333 5.25L3.54667 4.508ZM8.96 10.5C8.5 9.667 7.64667 9.1 6.66667 9.1C5.68667 9.1 4.83333 9.667 4.37333 10.5H3.25333C3.45333 9.968 3.76 9.499 4.14667 9.107L2.16 7.91C1.86 8.092 1.48667 8.106 1.16667 7.91C0.686667 7.623 0.526667 6.979 0.8 6.475C1.07333 5.971 1.68667 5.803 2.16667 6.09C2.48667 6.286 2.66 6.636 2.66 6.993L5.04667 8.442C5.53333 8.19 6.08 8.05 6.66667 8.05C8.22 8.05 9.54667 9.065 10.08 10.5H8.96Z"
          fill="black"
          fillOpacity="0.55"
        />
      </svg>
    </Box>
  );
};
