import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CheckBox,
  DateInput,
  Layer,
  MaskedInput,
  Select,
  Text,
} from "grommet";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { SaveButton } from "./SaveButton";
import { useForm } from "react-hook-form";
import { RecurrenceData, RecurrenceDataForm } from "../types";
import { Unit, ValidationError } from "../enums";
import { add, format, isBefore } from "date-fns";
import useMediaQuery from "../hooks/ueMediaQuery";
import { MobileCloseModalButton } from "./MobileCloseModalButton";
import { getOrdinalSuffixOf, monthsArray, weekdaysArray } from "../utils";

const RoundButton = styled(Button)<{ active: boolean }>`
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? colors.plPurple : colors.plWhite};
  color: ${(props) => (props.active ? colors.plWhite : colors.plDarkPurple)};
  width: 50px;
  height: 50px;
  text-align: center;
`;

const ErrorBox = styled(Box)<{ isError?: boolean }>`
  border: ${(props) =>
    props.isError ? `2px solid ${colors.plMagenta06}` : undefined};
  border-radius: 5px;
`;

const Label = styled(Text)`
  width: 140px;
`;

const weekDaysArray = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const IgnoreWeekendsOptionContainer = styled.div`
  display: flex;
  justify-content: end;
`;

export const RecurrenceModal = ({
  date,
  onSaveRecurrence,
  onClose,
}: {
  date: string | string[];
  onSaveRecurrence: (data: RecurrenceData, recurrenceRecap: string) => void;
  onClose: () => void;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  const [validationError, setValidationError] = useState<{
    type: ValidationError;
    message: string;
  } | null>();
  const [isRepeatEveryValid, setIsRepeatEveryValid] = useState<boolean>(true);
  const [ignoreWeekends, setIgnoreWeekends] = useState<boolean>(true);
  const [isWeekDaysValid, setIsWeekDaysValid] = useState<boolean>(true);
  const [recurrenceRecap, setRecurrenceRecap] = useState<string>("");
  const [formattedEndDate, setFormattedEndDate] = useState<string | string[]>(
    ""
  );

  const {
    register,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<RecurrenceDataForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      startDate: date,
      repeatEvery: { value: "1", unit: Unit.Day, weekDays: [] },
    },
  });

  const startDate = watch("startDate");
  const repeatEvery = watch("repeatEvery");
  const repeatEveryValue = watch("repeatEvery.value");
  const repeatEveryUnit = watch("repeatEvery.unit");
  const recurrenceDays = watch("repeatEvery.weekDays");

  useEffect(() => {
    if (date) {
      setValue("startDate", date);

      setFormattedEndDate(
        add(new Date(date as unknown as Date), {
          days: 1,
        }).toISOString()
      );

      setRecurrenceRecap("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    setRecurrenceRecap("");
  }, []);

  const onSelectWeekDay = (day: string) => {
    let weekDaysCopy = [...recurrenceDays];
    const isActive = recurrenceDays.includes(day);

    if (isActive) {
      const index = recurrenceDays.findIndex((d) => day === d);
      weekDaysCopy.splice(index, 1);
    } else {
      weekDaysCopy = [...recurrenceDays, day];
    }

    setValue("repeatEvery.weekDays", weekDaysCopy);
  };

  const isEndDateBeforeOrEqualToStart = () =>
    !isBefore(
      new Date(startDate as unknown as Date),
      new Date(formattedEndDate as unknown as Date)
    );

  useEffect(() => {
    if (repeatEveryUnit === Unit.Day) {
      setValue("repeatEvery.weekDays", []);
      setIgnoreWeekends(true);
    }

    const bothUnitAndValueProvided =
      repeatEveryUnit && !!repeatEveryValue && +repeatEveryValue > 0;
    const neitherUnitNorValueProvided = !repeatEveryUnit && !repeatEveryValue;

    const noDaysSelected = recurrenceDays.length === 0;

    setIsRepeatEveryValid(
      bothUnitAndValueProvided || neitherUnitNorValueProvided
    );

    setIsWeekDaysValid(
      neitherUnitNorValueProvided ||
        (repeatEveryUnit === Unit.Week && !noDaysSelected) ||
        (repeatEveryUnit === Unit.Day && noDaysSelected)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeatEveryUnit, repeatEveryValue, recurrenceDays.length]);

  useEffect(() => {
    if (
      recurrenceDays.length > 0 ||
      (!!repeatEveryValue && +repeatEveryValue > 0)
    ) {
      getRecurrenceRecap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrenceDays.length, repeatEveryUnit, repeatEveryValue]);

  useEffect(() => {
    // set UI validation errors
    if (
      isDirty ||
      recurrenceDays.length > 0 ||
      !!formattedEndDate ||
      !isRepeatEveryValid
    ) {
      switch (true) {
        case !formattedEndDate:
        case isEndDateBeforeOrEqualToStart():
          setValidationError({
            type: ValidationError.endDate,
            message: "Please enter a valid end date.",
          });
          return;
        case !isRepeatEveryValid:
        case !isWeekDaysValid:
          setValidationError({
            type: ValidationError.repeatEvery,
            message: "Please enter a repeat value and select the days below.",
          });
          return;
        default:
          setValidationError(null);
      }
    }
    getRecurrenceRecap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedEndDate, isRepeatEveryValid, isWeekDaysValid]);

  const isDisabled = () => {
    return (
      !formattedEndDate ||
      isEndDateBeforeOrEqualToStart() ||
      !isRepeatEveryValid ||
      !isWeekDaysValid
    );
  };

  const onSubmit = () => {
    const formData = getValues();
    let repeatEvery = Number(repeatEveryValue);

    onSaveRecurrence(
      {
        ...formData,
        endDate: formattedEndDate,
        repeatEvery: repeatEvery,
        repeatMode: repeatEveryUnit,
        weekDays: recurrenceDays,
        ignoreWeekends,
      },
      recurrenceRecap
    );
  };
  const getRecurrenceRecap = () => {
    const capitalizeDay = (day: string) => {
      return day.charAt(0).toUpperCase() + day.slice(1);
    };

    if (formattedEndDate) {
      const day = getOrdinalSuffixOf(
        new Date(formattedEndDate as unknown as Date).getDate()
      );
      const month =
        monthsArray[new Date(formattedEndDate as unknown as Date).getMonth()];
      const year = new Date(formattedEndDate as unknown as Date).getFullYear();
      const weekDay =
        weekdaysArray[new Date(formattedEndDate as unknown as Date).getDay()];

      const endDateString = `${weekDay} ${day} ${month} ${year}`;
      const recap =
        recurrenceDays.length > 0
          ? `Recurs every ${
              +repeatEveryValue! === 1
                ? `${repeatEveryUnit?.toLowerCase()}`
                : `${repeatEveryValue} ${repeatEveryUnit?.toLowerCase()}s`
              // @ts-ignore
            } on ${weekDaysArray
              .filter((day) => recurrenceDays.includes(day))
              .map((day) => capitalizeDay(day))
              .join(", ")} ${endDateString ? `until ${endDateString}` : ""}.`
          : `Recurs every ${
              +repeatEveryValue! === 1
                ? `${repeatEveryUnit?.toLowerCase()}`
                : `${repeatEveryValue} ${repeatEveryUnit?.toLowerCase()}s`
              // @ts-ignore
            } ${endDateString ? `until ${endDateString}` : ""}.`;
      return setRecurrenceRecap(recap);
    }
  };

  const recurrenceForm = (
    <form>
      <Box>
        <Text
          size={"large"}
          weight="bold"
          margin={{
            bottom: isDesktop ? "50px" : "15px",
            top: isDesktop ? "unset" : "15px",
          }}
        >
          Set recurrence
        </Text>
      </Box>
      <Box pad="small">
        <Box margin={{ bottom: "medium" }}>
          <Box direction="row">
            <Label weight="bold" margin={{ right: "12px" }}>
              Start
            </Label>
            <Box>
              <Text color={colors.plDarkGrey} weight="bold">
                {new Date(startDate as unknown as Date).toLocaleDateString(
                  "en-GB"
                )}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box margin={{ bottom: "medium" }}>
          <Box direction="row" align="center" justify="between">
            <Label margin={{ right: "12px" }} weight="bold">
              Repeat every
            </Label>
            <Box flex="shrink" margin={{ right: "12px" }} width="75px">
              <MaskedInput
                {...register("repeatEvery.value")}
                mask={[
                  {
                    length: [2],
                    regexp: /\d/,
                  },
                ]}
                style={{
                  height: "48px",
                  border:
                    validationError?.type === ValidationError.repeatEvery
                      ? "1px solid red"
                      : `1px solid ${colors.plStone1}`,
                  borderRadius: "5px",
                }}
                value={repeatEvery?.value}
                onChange={(event) =>
                  setValue("repeatEvery.value", event.target.value)
                }
              />
            </Box>
            <Box
              width={"155px"}
              style={{
                border:
                  validationError?.type === ValidationError.repeatEvery
                    ? "1px solid red"
                    : "unset",
                borderRadius:
                  validationError?.type === ValidationError.repeatEvery
                    ? "5px"
                    : "unset",
              }}
            >
              <Select
                {...register("repeatEvery.unit")}
                onChange={(event) =>
                  setValue("repeatEvery.unit", Unit[event.value as Unit])
                }
                value={repeatEveryUnit}
                width="200px"
                size={"medium"}
                options={Object.keys(Unit)}
              />
            </Box>
          </Box>
          <Box height="30px" align="start" justify="center">
            {validationError?.type === ValidationError.repeatEvery ? (
              <Text
                margin="0"
                size="small"
                color={colors.plMagenta06}
                textAlign="center"
              >
                {validationError?.message}
              </Text>
            ) : null}
          </Box>
          {repeatEvery.unit === Unit.Day ? (
            <IgnoreWeekendsOptionContainer>
              <CheckBox
                onClick={() => setIgnoreWeekends(!ignoreWeekends)}
                checked={ignoreWeekends}
              />
              <Text
                margin={{ left: "small" }}
                size="medium"
                color={colors.plDarkPurple06}
              >
                Ignore weekends
              </Text>
            </IgnoreWeekendsOptionContainer>
          ) : null}
        </Box>
        {repeatEvery.unit === Unit.Week ? (
          <Box
            direction="row"
            flex="grow"
            justify="evenly"
            style={{
              border:
                validationError?.type === ValidationError.repeatEvery
                  ? "1px solid red"
                  : "unset",
              borderRadius:
                validationError?.type === ValidationError.repeatEvery
                  ? "5px"
                  : "unset",
            }}
          >
            {weekDaysArray?.map((day) => (
              <RoundButton
                active={recurrenceDays?.includes(day)}
                onClick={() => onSelectWeekDay(day)}
              >
                {day.charAt(0).toUpperCase()}
              </RoundButton>
            ))}
          </Box>
        ) : null}
        <Box height="30px">
          {validationError?.type === ValidationError.weekDays ? (
            <Text
              margin="0"
              size="small"
              color={colors.plMagenta06}
              textAlign="center"
            >
              {validationError?.message}
            </Text>
          ) : null}
        </Box>
        <Box margin={{ bottom: "medium" }}>
          <Box direction="row" align="center" justify="between">
            <Label weight="bold" margin={{ right: "12px" }}>
              End
            </Label>
            <ErrorBox
              isError={validationError?.type === ValidationError.endDate}
            >
              <DateInput
                defaultValue={formattedEndDate}
                size="small"
                format="dd/mm/yyyy"
                calendarProps={{
                  daysOfWeek: true,
                  size: "small",
                  firstDayOfWeek: 1,
                }}
                onChange={({ value }) => {
                  //@ts-ignore
                  setFormattedEndDate(format(new Date(value), "P"));
                }}
              />
            </ErrorBox>
          </Box>
          {validationError?.type === ValidationError.endDate ? (
            <Box align="start" justify="center" height="30px">
              <Text
                margin="0"
                size="small"
                color={colors.plMagenta06}
                textAlign="center"
              >
                {validationError?.message}
              </Text>
            </Box>
          ) : null}
        </Box>
        <Box height="80px">
          {!isDisabled() ? <Text>{recurrenceRecap}</Text> : null}
        </Box>
        <SaveButton
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            return onSubmit();
          }}
          disabled={isDisabled()}
        >
          Add recurrence
        </SaveButton>
      </Box>
    </form>
  );

  return isDesktop ? (
    <Layer
      onEsc={onClose}
      onClickOutside={onClose}
      style={{
        position: "absolute",
        top: "350px",
        width: "500px",
        maxHeight: "600px",
      }}
    >
      <MobileCloseModalButton topRight={true} onClick={onClose} />
      <Box pad="medium">{recurrenceForm}</Box>
    </Layer>
  ) : (
    <Box pad={{ vertical: "medium" }}>{recurrenceForm}</Box>
  );
};
