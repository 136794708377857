import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { AvatarHeader } from "../components/AvatarHeader";
import { Switch } from "../components/Switch";
import { OccurrenceDuration } from "../enums";
import { EventModal } from "../components/EventModal";
import { EventCalendar } from "../components/EventCalendar";
import {
  AppUserViewModel,
  CalendarData,
  Month,
  Occurrence,
  OccurrenceViewModel,
} from "../types";
import {
  capitalizeFirstLetter,
  getDaysInMonth,
  monthsArray,
  sortWithUsersEntityFirst,
  weekdaysArray,
} from "../utils";
import OccurrenceService from "../services/occurrenceService";
import { add, format } from "date-fns";
import { useLocation } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { IconLegend } from "../components/IconLegend";
import { LoadingScreen } from "../components/LoadingScreen";
import { HorizontalSeparator } from "../components/HorizontalSeparator";
import useMediaQuery from "../hooks/ueMediaQuery";
import { Box, Select } from "grommet";
import { Down } from "grommet-icons";
import { useFetchDepartmentUsers } from "../hooks/useFetchDepartmentUsers";
import { useGetAppUser } from "../hooks/useGetAppUser";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${() => colors.plStone02};
  z-index: 1;
  width: 1350px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledSelect = styled(Select)<{ isDesktop: boolean }>`
  color: ${() => colors.plDarkPurple06};
  font-size: ${({ isDesktop }) => (isDesktop ? "18px;" : "15px")};
`;

const TOTAL_MONTHS_IN_SEASON = 12;

export const Calendar = () => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const location = useLocation();

  //const { avatars } = useGetAppUserAvatars();
  const { allUsers, isFetchingAllUsers } = useFetchDepartmentUsers();

  const occurrenceService = useMemo(() => new OccurrenceService(), []);

  const [selectedAppUser, setSelectedAppUser] = useState<AppUserViewModel>();

  const [appUsersNamesOptions, setAppUsersNamesOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const [seasonData, setSeasonData] = useState<CalendarData[]>([]);
  const [isLoadingSeasonData, setIsLoadingSeasonData] =
    useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | string[]>("");
  const [selectedDateOccurrence, setSelectedDateOccurrence] = useState<
    OccurrenceViewModel[]
  >([]);

  const [seasonLabel, setSeasonLabel] = useState<string>("");
  const [seasonYear, setSeasonYear] = useState<number>();

  const [filterValue, setFilterValue] = useState<string>("");

  const { appUser } = useGetAppUser();

  useEffect(() => {
    const year = new Date().getFullYear();
    setSeasonYear(year);
  }, []);

  // in case we're navigating here clicking on a user
  useEffect(() => {
    if (location.state.appUser) {
      setSelectedAppUser(location.state.appUser);
    } else {
      setSelectedAppUser(appUser);
    }
  }, [location, appUser]);

  useEffect(() => {
    setSeasonLabel(
      `${isDesktop ? "January - December" : "Jan - Dec"} ${seasonYear}`
    );
  }, [seasonYear]);

  // manage users dropdown and filtering
  useEffect(() => {
    if (allUsers && appUser?.id) {
      const appUserNamesOptions = allUsers.map((user) => ({
        id: user.id,
        name: capitalizeFirstLetter(user.name.replace(/[.]/g, " ")),
      }));

      if (!filterValue) {
        setAppUsersNamesOptions(
          sortWithUsersEntityFirst(appUser.id, appUserNamesOptions)
        );
      } else {
        setAppUsersNamesOptions(
          sortWithUsersEntityFirst(
            appUser.id,
            appUserNamesOptions.filter((opt) =>
              opt.name
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(filterValue.toLowerCase().replace(/\s/g, ""))
            )
          )
        );
      }
    }
  }, [allUsers, appUser?.id, filterValue]);

  const getSeasonData = async (
    selectedAppUserId: string,
    seasonYear: number
  ) => {
    setSeasonLabel(
      `${isDesktop ? "January - December" : "Jan - Dec"} ${seasonYear}`
    );

    const startOfSeason = new Date(`${seasonYear}-01-01`);
    const endOfSeason = new Date(`${seasonYear}-12-31`);

    setIsLoadingSeasonData(true);

    const occurrences = await occurrenceService.getOccurrencesByUserId({
      userId: selectedAppUserId,
      from_date: startOfSeason,
      to_date: endOfSeason,
    });

    const calendarData: CalendarData[] = [];
    const months = Array.from({ length: TOTAL_MONTHS_IN_SEASON }, (_, i) =>
      add(startOfSeason, { months: i })
    );

    for (const month of months) {
      const monthString = `${
        monthsArray[month.getMonth()]
      } ${month.getFullYear()}`;
      const days = getDaysAndOccurrences(
        month.getFullYear(),
        month.getMonth() + 1,
        occurrences
      );

      calendarData.push({
        month: monthString,
        days,
      });
    }

    setIsLoadingSeasonData(false);
    setSeasonData(calendarData);
  };

  const getDaysAndOccurrences = (
    year: number,
    month: number,
    occurrences: Occurrence[]
  ) => {
    const numberOfDaysInMonth = getDaysInMonth(year, month);
    const daysAndOccurrences = [];

    for (let i = 1; i <= numberOfDaysInMonth; i++) {
      const currentDate = new Date(year, month - 1, i);
      const occurrencesForDay = occurrences
        .filter((occurrence) => {
          const occurrenceDate = format(
            new Date(occurrence.occurrence_date),
            "P"
          );

          return currentDate.getTime() === new Date(occurrenceDate).getTime();
        })
        .map((occurrence) => ({
          id: occurrence.id,
          type: occurrence.occurrenceType,
          duration: occurrence.session_period,
          eventId: occurrence.eventId,
          appUserId: occurrence.appUserId,
          occurrenceDate: occurrence.occurrence_date,
          description: occurrence.description,
        }));

      daysAndOccurrences.push({
        number: i,
        month: monthsArray[month - 1],
        date: currentDate.toISOString(),
        weekDay: weekdaysArray[currentDate.getDay()],
        occurrences:
          occurrencesForDay.length > 0
            ? occurrencesForDay
            : [{ type: null, duration: OccurrenceDuration.fd }],
      });
    }

    return daysAndOccurrences;
  };

  useEffect(() => {
    if (selectedAppUser?.id && seasonYear) {
      // at every change of selected user or season, we refetch the calendar data
      getSeasonData(selectedAppUser?.id, seasonYear);
    }
  }, [selectedAppUser?.id, seasonYear]);

  const onFetchPreviousSeason = async () => {
    if (seasonYear) {
      setSeasonYear(seasonYear + 1);
    }
  };

  const onFetchNextSeason = async () => {
    if (seasonYear) {
      setSeasonYear(seasonYear - 1);
    }
  };

  const onSelectDate = (date: string | string[], monthEvent: Month) => {
    setSelectedDate(date);
    const selectedDateOccurrence = monthEvent.days.find(
      (day) => day.number === new Date(date as unknown as Date).getDate()
    )?.occurrences;

    if (selectedDateOccurrence) {
      setSelectedDateOccurrence(selectedDateOccurrence);
    }
  };

  const onSuccess = async () => {
    setSelectedDate("");

    if (selectedAppUser?.id && seasonYear) {
      getSeasonData(selectedAppUser?.id, seasonYear);
    }
  };

  if (!appUser?.id) {
    return <LoadingScreen />;
  }

  return (
    <Container>
      {isDesktop ? (
        <>
          <PageHeader>
            <Box style={{ flexShrink: 0 }}>
              <AvatarHeader appUser={appUser} />
            </Box>

            <IconLegend />
          </PageHeader>
          <HorizontalSeparator />
        </>
      ) : null}

      <Box width="100%" height="50px">
        <Box direction="row" align="center" justify="between" pad="0 70px">
          {!isFetchingAllUsers && selectedAppUser ? (
            <Box width="medium">
              <StyledSelect
                isDesktop={isDesktop}
                alignSelf="start"
                plain
                icon={<Down color={colors.plPurple} />}
                options={appUsersNamesOptions.map((user) => user.name)}
                value={capitalizeFirstLetter(selectedAppUser?.name!)}
                onChange={({ option }) => {
                  const newSelectedUser = allUsers?.find(
                    (user) =>
                      capitalizeFirstLetter(user.name.replace(/[.]/g, " ")) ===
                      option
                  );
                  if (newSelectedUser) {
                    setSelectedAppUser(newSelectedUser);
                    setFilterValue("");
                  }
                }}
                onSearch={(filterValue: string) => setFilterValue(filterValue)}
              />
            </Box>
          ) : null}
          <Box
            width="medium"
            style={{
              justifyContent: isDesktop ? "unset" : "center",
            }}
          >
            <Switch
              showResetButton={seasonYear !== new Date().getFullYear()}
              onResetToCurrentSeason={() =>
                setSeasonYear(new Date().getFullYear())
              }
              isLoading={isLoadingSeasonData}
              label={seasonLabel}
              onFetchNext={onFetchPreviousSeason}
              onFetchPrevious={onFetchNextSeason}
            />
          </Box>
        </Box>
      </Box>
      <HorizontalSeparator />
      {isLoadingSeasonData ? (
        <LoadingScreen />
      ) : (
        <PageContent data-testid={"calendar-body"}>
          {!!appUser &&
            selectedAppUser &&
            seasonData?.map((monthData) => (
              <EventCalendar
                allUsers={allUsers}
                isLoading={isLoadingSeasonData}
                selectedAppUser={selectedAppUser}
                appUser={appUser}
                key={monthData.month}
                occurrences={monthData}
                onSelectDate={(date) => onSelectDate(date, monthData)}
              />
            ))}
        </PageContent>
      )}

      {!!selectedDate && selectedAppUser && (
        <EventModal
          targetUser={selectedAppUser}
          appUser={appUser}
          date={selectedDate}
          selectedDateOccurrence={selectedDateOccurrence}
          onCloseModal={() => setSelectedDate("")}
          onSuccess={onSuccess}
        />
      )}
    </Container>
  );
};
