import React, { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionPanel, Box, CheckBox, Layer, Text } from "grommet";
import styled from "styled-components";
import { colors } from "../assets/theme";
import {
  DefaultOffice,
  OccurrenceDuration,
  OccurrenceDurationLabels,
  OccurrenceType,
} from "../enums";
import { clubLogos } from "../assets/club_logos/48";
import { EventLabel, EventOption } from "./EventOption";
import { SaveButton } from "./SaveButton";
import { RecurrenceModal } from "./RecurrenceModal";
import { DeleteButton } from "./DeleteButton";
import { DurationBox, getIconForOccurrenceType } from "./DayInfoTooltip";
import {
  capitalizeFirstLetter,
  getOrdinalSuffixOf,
  monthsArray,
  weekdaysArray,
  weekDaysToNumber,
} from "../utils";
import {
  AppUserViewModel,
  CreateEventPayload,
  OccurrenceViewModel,
  RecurrenceData,
} from "../types";
import { Loading } from "./Loading";
import { ErrorMessage } from "./ErrorMessage";
import { Search } from "grommet-icons";
import { HorizontalSeparator } from "./HorizontalSeparator";
import { VerticalSeparator } from "./VerticalSeparator";
import { Remote35 } from "./svgs/Remote/Remote35";
import { Leave35 } from "./svgs/Leave/Leave35";
import { Office35 } from "./svgs/Office/Office35";
import EventService from "../services/eventService";
import OccurrenceService from "../services/occurrenceService";
import useMediaQuery from "../hooks/ueMediaQuery";
import { MobileCloseModalButton } from "./MobileCloseModalButton";
import { Stroller35 } from "./svgs/Stroller/Stroller35";
import { Sick35 } from "./svgs/Sick/Sick35";
import { EventDescriptionList, secondaryTags } from "./EventDescriptionList";
import { AllClubs } from "../assets/clubs";
import AppUserSettingsService from "../services/appUserSettingsService";
import { Travel35 } from "./svgs/Travel/Travel35";

const Header = styled.span`
  font-size: 18px;
  color: ${() => colors.plDarkPurple06};
  margin-bottom: 20px;
`;

const RecurringOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  height: 50px;
`;

const StyledTextInput = styled.input`
  position: relative;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  padding: 10px 5px 10px 20px;
  border: ${() => `1px solid ${colors.plStone1}`};
`;

const ScrollableContainer = styled.div`
  height: 305px;
  flex-grow: 1;
  width: 350px;
  overflow-y: auto;
  background-color: ${() => colors.plStone02};
  padding: 15px 0;
  border-radius: 5px;
  span {
    text-align: center;
  }
`;

const Banner = styled(Box)<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 5px;
  padding: 10px;
  flex-shrink: 0;
`;

const OfficeAccordionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  [role="region"] {
    border-bottom: none;
  }
  div:has(svg[aria-label="FormDown"]) {
    padding: 0;
  }
  div:has(svg[aria-label="FormUp"]) {
    padding: 0;
  }
`;

const officeEvents = [
  OccurrenceType.londonbrunel,
  OccurrenceType.newyork,
  OccurrenceType.singapore,
];

const allEvents = Object.keys(OccurrenceType).filter(
  (event) => event !== OccurrenceType.holiday && event !== OccurrenceType.brunel
);

const clubEvents = allEvents
  .filter(
    (event) =>
      // brunel cannot be removed from the OccurrenceType because of legacy
      event !== OccurrenceType.brunel &&
      event !== OccurrenceType.londonbrunel &&
      event !== OccurrenceType.newyork &&
      event !== OccurrenceType.singapore &&
      event !== OccurrenceType.holiday &&
      event !== OccurrenceType.leave &&
      event !== OccurrenceType.travel &&
      event !== OccurrenceType.remote &&
      event !== OccurrenceType.parentalleave.replace(/\s/g, "") &&
      event !== OccurrenceType.sickleave.replace(/\s/g, "")
  )
  // only clubs that are currently in Premier League
  .filter(
    (clubName: string) =>
      AllClubs.find((club) => club.clubKey === clubName)?.inPremierLeague
  )
  .sort((a: string, b: string) => (a !== b ? (a < b ? -1 : 1) : 0));

export const EventModal = ({
  date,
  selectedDateOccurrence,
  onCloseModal,
  onSuccess,
  appUser,
  targetUser,
}: {
  selectedDateOccurrence: OccurrenceViewModel[];
  date: string | string[];
  onCloseModal: () => void;
  onSuccess: () => void;
  appUser: AppUserViewModel;
  targetUser?: AppUserViewModel;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const isMinWidth1000 = useMediaQuery("(min-width:1000px)");
  const eventService = new EventService();
  const occurrenceService = useMemo(() => new OccurrenceService(), []);
  const appUserSettingsService = useMemo(
    () => new AppUserSettingsService(),
    []
  );

  const [viewMoreOptions, setViewMoreOptions] = useState<boolean>(false);
  const [occurrenceType, setOccurrenceType] = useState<OccurrenceType>(
    OccurrenceType.londonbrunel
  );
  const [secondaryTag, setSecondaryTag] = useState<string>("");
  const [isRecurring, setIsRecurring] = useState<boolean>(false);
  const [showRecurrenceModal, setShowRecurrenceModal] =
    useState<boolean>(false);
  const [dateString, setDateString] = useState<string>("");
  const [targetUserData, setTargetUserData] = useState<{
    targetUserId: string;
    targetUserTeamId: string;
    targetUserDefaultOffice: DefaultOffice;
  }>(
    {} as {
      targetUserId: string;
      targetUserTeamId: string;
      targetUserDefaultOffice: DefaultOffice.londonbrunel;
    }
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isCreatingOccurrence, setIsCreatingOccurrence] =
    useState<boolean>(false);

  const [recurrence, setRecurrence] = useState<RecurrenceData>(
    {} as RecurrenceData
  );
  const [recurrenceRecap, setRecurrenceRecap] = useState<string>("");
  const [occurrenceDurationLabel, setOccurrenceDurationLabel] =
    useState<OccurrenceDurationLabels>(OccurrenceDurationLabels["Full day"]);

  const [occurrenceTypeFilterValue, setOccurrenceTypeFilterValue] =
    useState<string>("");

  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (date) {
      setError("");
      const day = getOrdinalSuffixOf(
        new Date(date as unknown as Date).getDate()
      );
      const month = monthsArray[new Date(date as unknown as Date).getMonth()];
      const year = new Date(date as unknown as Date).getFullYear();
      const weekDay = weekdaysArray[new Date(date as unknown as Date).getDay()];

      const dateString = `${weekDay} ${day} ${month} ${year}`;

      setDateString(dateString);
    }
  }, [date]);

  useEffect(() => {
    if (!isRecurring) {
      setRecurrenceRecap("");
    }
  }, [isRecurring]);

  useEffect(() => {
    // on change of occurrence type, select the default secondary tag
    const formattedOccurrenceType = occurrenceType.replace(/\s/g, "");
    const sameEvent = selectedDateOccurrence
      .map((occ) => occ.type)
      .find((type) => type === formattedOccurrenceType);
    const sameSecondary = selectedDateOccurrence.find(
      (occ) => occ.type === sameEvent
    )?.description;

    const defaultSecondaryTag =
      sameSecondary ||
      secondaryTags[formattedOccurrenceType as OccurrenceType]?.filter(
        (secondaryTag) => secondaryTag.isDefault
      )[0]?.label ||
      "";
    setSecondaryTag(defaultSecondaryTag);
  }, [occurrenceType]);

  useEffect(() => {
    const targetUserFound = targetUser?.id ? targetUser : appUser;
    if (targetUserFound) {
      (async () => {
        const settings = await appUserSettingsService.getAppUserSettings(
          targetUserFound.appUserSettingsId
        );

        setTargetUserData({
          targetUserId: targetUserFound.id,
          targetUserTeamId: targetUserFound.teamId,
          targetUserDefaultOffice: settings.defaultOffice,
        });

        setOccurrenceType(
          OccurrenceType[settings.defaultOffice as keyof typeof OccurrenceType]
        );
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser.id]);

  const createOccurrenceMutation = async (eventPayload: CreateEventPayload) => {
    setIsCreatingOccurrence(true);
    return eventService
      .createEvent(eventPayload)
      .then(async () => {
        await onSuccess();
        onCloseModal();
        setIsCreatingOccurrence(false);
      })
      .catch((error: string) => {
        setError(
          "Ooops! " + (error as string).toString().replace("Error: ", "")
        );
        setIsCreatingOccurrence(false);
      });
  };

  const isExistingOccurrence =
    selectedDateOccurrence[0].appUserId === targetUserData.targetUserId;

  const onSave = async () => {
    setIsLoading(true);
    if (
      !occurrenceType ||
      (isRecurring && (!recurrence.startDate || !recurrence.endDate))
    ) {
      setIsLoading(false);
      return;
    }

    let occurrenceDuration =
      occurrenceDurationLabel === "Morning"
        ? OccurrenceDuration.am
        : occurrenceDurationLabel === "Afternoon"
        ? OccurrenceDuration.pm
        : OccurrenceDuration.fd;

    let weekDaysToNumberArray: (0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined)[] = [];

    if (recurrence && recurrence.weekDays?.length > 0) {
      weekDaysToNumberArray = recurrence?.weekDays.map((day) =>
        weekDaysToNumber(day)
      );
    }

    const { appUserAvatarId, ...appUserWithoutAvatar } = appUser;

    if (!!appUser) {
      const eventPayload = {
        session_period: occurrenceDuration,
        weekDays: weekDaysToNumberArray || [],
        start_date: date,
        end_date: recurrence?.endDate || date,
        appUserId: appUser.id,
        appUser: appUserWithoutAvatar,
        teamId: appUser.teamId,
        targetUserId: targetUserData.targetUserId,
        targetUserTeamId: targetUserData.targetUserTeamId,
        tagDefinitionId: occurrenceType.replace(/\s/g, ""),
        description: secondaryTag,
        repeatEvery: recurrence?.repeatEvery || 1,
        repeatMode: recurrence.repeatMode,
        ignoreWeekends: recurrence?.ignoreWeekends,
      };

      if (isExistingOccurrence) {
        // CHECK IF UPDATING
        const occurrenceToUpdate = selectedDateOccurrence.find(
          (occ) => occ.duration === occurrenceDuration && !!occ.type
        );

        if (occurrenceToUpdate) {
          // UPDATING EXISTING PARTIAL OCCURRENCE
          if (occurrenceToUpdate?.id && occurrenceToUpdate?.eventId) {
            await occurrenceService.deleteOccurrenceById({
              appUserId: appUser?.id,
              userId: appUser?.id,
              occurrences: [
                {
                  occurrenceId: occurrenceToUpdate.id,
                  eventId: occurrenceToUpdate.eventId,
                },
              ],
            });
          }
        } else {
          // UPDATING AN EXISTING FD OCCURRENCE OR UPDATING WITH A FD OCCURRENCE
          const isExistingFullDayOccurrence = selectedDateOccurrence.some(
            (occ) => occ.duration === OccurrenceDuration.fd && !!occ.type
          );

          if (
            occurrenceDuration === OccurrenceDuration.fd ||
            isExistingFullDayOccurrence
          ) {
            await Promise.all(
              selectedDateOccurrence.map((occ) => {
                return occurrenceService.deleteOccurrenceById({
                  appUserId: appUser?.id,
                  userId: appUser?.id,
                  occurrences: [
                    {
                      // @ts-ignore
                      occurrenceId: occ.id,
                      // @ts-ignore
                      eventId: occ.eventId,
                    },
                  ],
                });
              })
            );
          }
        }
      }
      // here the isCreatingOccurrence will kick in and we no longer need the isLoading
      setIsLoading(false);

      // CREATE NEW
      await createOccurrenceMutation(eventPayload);
    }
  };

  const onDeleteOccurrences = async (
    occurrences: {
      eventId: string;
      occurrenceId: string;
    }[]
  ) => {
    if (appUser?.id) {
      setIsDeleting(true);
      await occurrenceService
        .deleteOccurrenceById({
          appUserId: appUser?.id,
          userId: appUser?.id,
          occurrences: occurrences,
        })
        .then(() => {
          setIsDeleting(false);
          setError("");
          onSuccess();
        })
        .catch((err) => {
          setIsDeleting(false);
          setError(err.toString());
        });
    }
  };

  const getOfficeAccordionLabel = (
    <Box direction="row" align="center">
      <Office35 positionAbsolute={false} />
      <EventLabel
        style={{
          fontWeight: officeEvents.includes(occurrenceType) ? "bold" : "unset",
        }}
      >
        {occurrenceType === OccurrenceType.londonbrunel ||
        occurrenceType === OccurrenceType.newyork ||
        occurrenceType === OccurrenceType.singapore
          ? capitalizeFirstLetter(occurrenceType)
          : "Office"}
      </EventLabel>
    </Box>
  );

  const UnfilteredEventsList = () => (
    <>
      <OfficeAccordionContainer data-testid={"office-selector"}>
        <Accordion flex="grow" animate>
          <AccordionPanel
            style={{
              color: colors.plDarkPurple,
              height: "48px",
              padding: "0px 10px",
            }}
            label={getOfficeAccordionLabel}
          >
            {officeEvents.map((office) => (
              <EventOption
                style={{ margin: "5px 0" }}
                key={office}
                disabled={isLoading || isCreatingOccurrence}
                onClick={setOccurrenceType}
                eventType={occurrenceType}
                label={
                  OccurrenceType[
                    office.replace(/\s/g, "") as keyof typeof OccurrenceType
                  ]
                }
              />
            ))}
          </AccordionPanel>
        </Accordion>
      </OfficeAccordionContainer>
      <EventOption
        disabled={isLoading || isCreatingOccurrence}
        onClick={setOccurrenceType}
        eventType={occurrenceType}
        icon={<Remote35 positionAbsolute={false} />}
        label={OccurrenceType.remote}
      />
      <EventOption
        disabled={isLoading || isCreatingOccurrence}
        onClick={setOccurrenceType}
        eventType={occurrenceType}
        icon={<Leave35 plainBox positionAbsolute={false} />}
        label={OccurrenceType.leave}
      />
      <EventOption
        disabled={isLoading || isCreatingOccurrence}
        onClick={setOccurrenceType}
        eventType={occurrenceType}
        icon={<Sick35 positionAbsolute={false} />}
        label={OccurrenceType.sickleave}
      />
      <EventOption
        disabled={isLoading || isCreatingOccurrence}
        onClick={setOccurrenceType}
        eventType={occurrenceType}
        icon={<Stroller35 positionAbsolute={false} />}
        label={OccurrenceType.parentalleave}
      />
      <EventOption
        disabled={isLoading || isCreatingOccurrence}
        onClick={setOccurrenceType}
        eventType={occurrenceType}
        icon={<Travel35 positionAbsolute={false} />}
        label={OccurrenceType.travel}
      />
      {!viewMoreOptions ? (
        <Box>
          <Text
            onClick={() => setViewMoreOptions(true)}
            color={colors.plPurple}
            weight="bold"
            textAlign="center"
            margin={"15px 0"}
            alignSelf="center"
            style={{ cursor: "pointer" }}
          >
            View more...
          </Text>
        </Box>
      ) : (
        clubEvents.map((club) => (
          <EventOption
            disabled={isLoading || isCreatingOccurrence}
            key={club}
            onClick={setOccurrenceType}
            eventType={occurrenceType}
            icon={
              <img
                // @ts-ignore
                src={clubLogos[club]}
                height="35"
                alt={club}
              />
            }
            label={OccurrenceType[club as keyof typeof OccurrenceType]}
          />
        ))
      )}
    </>
  );

  const FilteredEventsList = () => (
    <>
      {allEvents
        .filter((eventLabel) =>
          eventLabel.includes(
            occurrenceTypeFilterValue.toLowerCase().replace(/\s/g, "")
          )
        )
        .map((eventLabel) => {
          return eventLabel ===
            OccurrenceType.londonbrunel.replace(/\s/g, "") ||
            eventLabel === OccurrenceType.singapore ||
            eventLabel === OccurrenceType.newyork.replace(/\s/g, "") ||
            eventLabel === OccurrenceType.leave ||
            eventLabel === OccurrenceType.remote ||
            eventLabel === OccurrenceType.parentalleave.replace(/\s/g, "") ||
            eventLabel === OccurrenceType.sickleave.replace(/\s/g, "") ||
            eventLabel === OccurrenceType.travel.replace(/\s/g, "") ? (
            <EventOption
              disabled={isLoading || isCreatingOccurrence}
              onClick={setOccurrenceType}
              eventType={occurrenceType}
              icon={
                eventLabel === OccurrenceType.londonbrunel.replace(/\s/g, "") ||
                eventLabel === OccurrenceType.singapore ||
                eventLabel === OccurrenceType.newyork.replace(/\s/g, "") ? (
                  <Office35 />
                ) : eventLabel === OccurrenceType.remote ? (
                  <Remote35 />
                ) : eventLabel === OccurrenceType.leave ? (
                  <Leave35 plainBox />
                ) : eventLabel === OccurrenceType.sickleave ? (
                  <Sick35 />
                ) : (
                  <Stroller35 />
                )
              }
              label={OccurrenceType[eventLabel as keyof typeof OccurrenceType]}
            />
          ) : (
            <EventOption
              disabled={isLoading || isCreatingOccurrence}
              key={eventLabel}
              onClick={setOccurrenceType}
              eventType={occurrenceType}
              icon={
                <img
                  // @ts-ignore
                  src={clubLogos[eventLabel]}
                  height="30"
                  alt={eventLabel}
                />
              }
              label={OccurrenceType[eventLabel as keyof typeof OccurrenceType]}
            />
          );
        })}
    </>
  );

  const onResetRecurrenceForm = () => {
    setError("");
    setIsRecurring(false);
    setRecurrence({} as RecurrenceData);
  };

  return (
    <Layer
      full={!isDesktop}
      onEsc={onCloseModal}
      onClickOutside={onCloseModal}
      style={{
        width: !isMinWidth1000 ? "100vw" : "880px",
        height: !isMinWidth1000 ? "100%" : "unset",
        alignSelf: !isMinWidth1000 ? "center" : "unset",
      }}
    >
      <MobileCloseModalButton topRight={true} onClick={onCloseModal} />
      <Box
        pad={{ horizontal: "medium", top: "medium", bottom: "small" }}
        overflow="auto"
      >
        <Header>
          <strong>{dateString}</strong>
        </Header>
        {targetUser?.managerId === appUser.id &&
        targetUser?.id !== appUser.id ? (
          <Banner
            color={colors.plPurple01}
            margin={{ bottom: "small" }}
            background={colors.plStone02}
            align={!isMinWidth1000 ? "start" : "unset"}
          >
            <Box pad="small">
              <Text>
                Creating event for{" "}
                <Text weight="bold" color={colors.plDarkPurple}>
                  {targetUser?.name.replace(".", " ")}
                </Text>
                .
              </Text>
            </Box>
          </Banner>
        ) : null}
        {isExistingOccurrence ? (
          <Banner
            color={colors.plMagenta01}
            align={!isMinWidth1000 ? "start" : "unset"}
          >
            <Box direction="row" margin={{ bottom: "10px" }}>
              There are already events for this date.
            </Box>
            <Box pad="xxsmall" width="100%">
              {selectedDateOccurrence.map((occurrence, i) => (
                <Box
                  key={`${occurrence.type}${i}`}
                  justify="between"
                  direction="row"
                  height="50px"
                >
                  <Box direction="row" align="center">
                    <DurationBox>
                      {occurrence.duration.toUpperCase()}
                    </DurationBox>
                    <Box direction="row">
                      <Box
                        width="40px"
                        height="40px"
                        align="center"
                        justify="center"
                      >
                        {getIconForOccurrenceType(occurrence, "20")}
                      </Box>
                      <Box direction="column" alignSelf="center">
                        <Text
                          weight="bold"
                          margin={{ horizontal: "15px" }}
                          color={colors.plDarkPurple06}
                        >
                          {occurrence?.type
                            ? `${capitalizeFirstLetter(
                                OccurrenceType[
                                  occurrence.type as keyof typeof OccurrenceType
                                ]
                              )}`
                            : "n/a"}
                        </Text>
                        {occurrence.description && (
                          <Text
                            margin={{ horizontal: "15px" }}
                            size="small"
                            color={colors.plDarkPurple}
                          >
                            {occurrence.description}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <DeleteButton
                    disabled={isLoading || isCreatingOccurrence || isDeleting}
                    onClick={() =>
                      !isDeleting && occurrence?.id && occurrence.eventId
                        ? onDeleteOccurrences([
                            {
                              eventId: occurrence.eventId,
                              occurrenceId: occurrence.id,
                            },
                          ])
                        : null
                    }
                  >
                    Delete
                  </DeleteButton>
                </Box>
              ))}
              {isDeleting || isCreatingOccurrence || isLoading ? (
                <Loading />
              ) : selectedDateOccurrence.length > 1 &&
                selectedDateOccurrence.every(
                  (occurrence) => !!occurrence.id
                ) ? (
                <DeleteButton
                  disabled={isLoading || isDeleting || isCreatingOccurrence}
                  onClick={() => {
                    const occurrences = selectedDateOccurrence.map((occ) => {
                      if (occ.eventId && occ.id) {
                        return {
                          eventId: occ.eventId,
                          occurrenceId: occ.id,
                        };
                      }
                      return [];
                    });
                    //@ts-ignore
                    onDeleteOccurrences(occurrences);
                  }}
                >
                  Delete all
                </DeleteButton>
              ) : null}
            </Box>
          </Banner>
        ) : null}
        <HorizontalSeparator />
        {isDesktop ? (
          <>
            <Box direction="row" justify="between">
              <Box direction="column" style={{ flexGrow: 2 }}>
                <Box direction="row" align="start" margin={{ bottom: "small" }}>
                  <Search style={{ padding: "10px" }} />
                  <StyledTextInput
                    type="search"
                    placeholder="Search"
                    name="search"
                    value={occurrenceTypeFilterValue}
                    onChange={(occurrence) =>
                      setOccurrenceTypeFilterValue(occurrence.target.value)
                    }
                  />
                </Box>
                <Box direction="row">
                  <ScrollableContainer>
                    {occurrenceTypeFilterValue ? (
                      <FilteredEventsList />
                    ) : (
                      <UnfilteredEventsList />
                    )}
                  </ScrollableContainer>
                  {!!occurrenceType &&
                  secondaryTags[
                    occurrenceType.replace(/\s/g, "") as OccurrenceType
                  ].length > 0 ? (
                    <>
                      <VerticalSeparator margin="10px" />
                      <ScrollableContainer>
                        <EventDescriptionList
                          disabled={isLoading || isCreatingOccurrence}
                          occurrenceType={occurrenceType}
                          onChangeSecondaryTag={setSecondaryTag}
                          selectedDateOccurrence={selectedDateOccurrence}
                        />
                      </ScrollableContainer>
                    </>
                  ) : null}
                </Box>
              </Box>

              <VerticalSeparator margin="20px" />
              <Box
                direction="column"
                justify="evenly"
                align="center"
                style={{ flexGrow: 1, flexShrink: 0 }}
              >
                <Text margin={{ bottom: "small" }}>Event duration:</Text>
                <Box
                  direction="column"
                  justify="evenly"
                  align="center"
                  style={{ flexGrow: 1, borderRadius: "5px" }}
                  background={colors.plStone02}
                  width="100%"
                  pad={{ horizontal: "small" }}
                >
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels["Full day"]
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels["Full day"]
                      )
                    }
                    height="45px"
                  >
                    FULL DAY
                  </DurationBox>
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels.Morning
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels.Morning
                      )
                    }
                    height="45px"
                  >
                    AM
                  </DurationBox>
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels.Afternoon
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels.Afternoon
                      )
                    }
                    height="45px"
                  >
                    PM
                  </DurationBox>
                  {!isExistingOccurrence ? (
                    <RecurringOptionContainer>
                      <Text
                        size="medium"
                        color={colors.plDarkPurple06}
                        margin={{ bottom: "5px" }}
                      >
                        Recurring event?
                      </Text>
                      <CheckBox
                        onClick={() => {
                          setIsRecurring(!isRecurring);
                          setShowRecurrenceModal(true);
                        }}
                        checked={isRecurring}
                      />
                    </RecurringOptionContainer>
                  ) : null}
                </Box>
              </Box>
            </Box>{" "}
          </>
        ) : (
          <>
            <Box style={{ flexShrink: 0 }} direction="column" justify="between">
              <Box direction="column" style={{ flexGrow: 2 }}>
                <Box direction="row" align="start" margin={{ bottom: "small" }}>
                  <Search style={{ padding: "10px 10px" }} />
                  <StyledTextInput
                    type="search"
                    placeholder="Search"
                    name="search"
                    value={occurrenceTypeFilterValue}
                    onChange={(occurrence) =>
                      setOccurrenceTypeFilterValue(occurrence.target.value)
                    }
                  />
                </Box>
                <Box direction="row">
                  <ScrollableContainer>
                    {occurrenceTypeFilterValue ? (
                      <FilteredEventsList />
                    ) : (
                      <UnfilteredEventsList />
                    )}
                  </ScrollableContainer>
                  {!!occurrenceType &&
                  secondaryTags[
                    occurrenceType.replace(/\s/g, "") as OccurrenceType
                  ].length > 0 ? (
                    <>
                      <VerticalSeparator margin="10px" />
                      <ScrollableContainer>
                        <EventDescriptionList
                          disabled={isLoading || isCreatingOccurrence}
                          occurrenceType={occurrenceType}
                          onChangeSecondaryTag={setSecondaryTag}
                          selectedDateOccurrence={selectedDateOccurrence}
                        />
                      </ScrollableContainer>
                    </>
                  ) : null}
                </Box>
              </Box>
              <Box
                direction="column"
                justify="evenly"
                align="center"
                style={{ flexGrow: 1 }}
                pad={{ vertical: "10px" }}
              >
                <HorizontalSeparator />
                <Text margin={{ bottom: "small" }}>Event duration:</Text>
                <Box
                  direction="row"
                  justify="evenly"
                  align="center"
                  style={{ flexGrow: 1, borderRadius: "5px" }}
                  background={colors.plStone02}
                  width="100%"
                  pad={{ vertical: "10px" }}
                >
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels["Full day"]
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels["Full day"]
                      )
                    }
                    height="45px"
                  >
                    FULL DAY
                  </DurationBox>
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels.Morning
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels.Morning
                      )
                    }
                    height="45px"
                  >
                    AM
                  </DurationBox>
                  <DurationBox
                    isActive={
                      occurrenceDurationLabel ===
                      OccurrenceDurationLabels.Afternoon
                    }
                    onClick={() =>
                      setOccurrenceDurationLabel(
                        OccurrenceDurationLabels.Afternoon
                      )
                    }
                    height="45px"
                  >
                    PM
                  </DurationBox>
                  {!isExistingOccurrence ? (
                    <RecurringOptionContainer>
                      <Text
                        size="medium"
                        color={colors.plDarkPurple06}
                        margin={{ bottom: "5px" }}
                      >
                        Recurring event?
                      </Text>
                      <CheckBox
                        onClick={() => {
                          setIsRecurring(!isRecurring);
                          setShowRecurrenceModal(true);
                        }}
                        checked={isRecurring}
                      />
                    </RecurringOptionContainer>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </>
        )}

        <HorizontalSeparator margin="5px" />

        {recurrenceRecap ? (
          <Box pad="12px 0">
            <Text>{recurrenceRecap}</Text>
          </Box>
        ) : null}

        {isRecurring && showRecurrenceModal ? (
          <RecurrenceModal
            date={date}
            onSaveRecurrence={async (
              recurrence: RecurrenceData,
              recurrenceRecap: string
            ) => {
              setRecurrence(recurrence);
              setRecurrenceRecap(recurrenceRecap);
              setShowRecurrenceModal(false);
            }}
            onClose={() => setIsRecurring(false)}
          />
        ) : null}
      </Box>
      <Box
        margin={{ bottom: isDesktop ? "unset" : "100px" }}
        align="center"
        justify="center"
        pad={{ vertical: "small" }}
      >
        {isCreatingOccurrence || isLoading ? (
          <Box pad={{ vertical: "5px" }}>
            <Loading />
          </Box>
        ) : (
          <>
            <Box>{error ? <ErrorMessage error={error} /> : null}</Box>
            <SaveButton
              onClick={!error ? onSave : onResetRecurrenceForm}
              disabled={
                isCreatingOccurrence ||
                !occurrenceType ||
                isDeleting ||
                isCreatingOccurrence ||
                (showRecurrenceModal && isRecurring)
              }
            >
              {error ? "OK" : isExistingOccurrence ? "Update" : "Save"}
            </SaveButton>
          </>
        )}
      </Box>
    </Layer>
  );
};
