import styled from "styled-components";
import { Box } from "grommet";
import { colors } from "../assets/theme";
import React, { ReactNode } from "react";
import useMediaQuery from "../hooks/ueMediaQuery";

const StyledBox = styled(Box)<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: min-content;
  width: 100%;
  background-color: ${() => colors.plStone02};
  position: sticky;
  top: 60px;
  z-index: 4;
  padding: 24px 70px;
  justify-content: space-between;
`;

export const PageHeader = ({ children }: { children?: ReactNode }) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return (
    <StyledBox
      isDesktop={isDesktop}
      alignContent="center"
      align="center"
      wrap
      gap="40px"
    >
      {children}
    </StyledBox>
  );
};
