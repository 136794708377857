import { Box, Text } from "grommet";
import { colors } from "../../assets/theme";
import { format } from "date-fns";
import { UpcomingOccurrences } from "../UpcomingOccurrences";
import { Loading } from "../Loading";
import React from "react";
import {
  SummaryCard,
  SummaryLabel,
  SummarySubtitle,
  SummaryTitle,
} from "./SummaryCards";
import { Day } from "../../types";

export const UpcomingEventsSummaryCard = ({
  fromDate,
  toDate,
  isLoadingUpcomingOccurrences,
  upcomingOccurrences,
}: {
  fromDate?: Date;
  toDate?: Date;
  isLoadingUpcomingOccurrences: boolean;
  upcomingOccurrences: Day[];
}) => {
  return (
    <SummaryCard round={false}>
          <SummaryLabel>
            <SummaryTitle>My Events</SummaryTitle>
            <SummarySubtitle>{`${format(fromDate!, "do LLL")} - ${format(
              toDate!,
              "do LLL"
            )}`}</SummarySubtitle>
          </SummaryLabel>
        <Box
          round={false}
          background={colors.plWhite}
          align="center"
          style={{
            padding: "0px 12px",
          }}
        >
          {!isLoadingUpcomingOccurrences ? (
            upcomingOccurrences.length > 0 ? (
              upcomingOccurrences.map((upcomingEvent, i) => (
                <UpcomingOccurrences
                  day={upcomingEvent}
                  key={`${upcomingEvent}${i}`}
                  month={upcomingEvent.month}
                />
              ))
            ) : (
              <Box pad="12px 0" alignContent="center">
                <Text>You have no events.</Text>
              </Box>
            )
          ) : (
            <Loading />
          )}
        </Box>
    </SummaryCard>
  );
};
