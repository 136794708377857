import { AppBar } from "../../components/AppBar";
import React, { ReactElement, useState } from "react";
import { Box } from "grommet";
import { ErrorPage } from "../ErrorPage";
import { LoadingScreen } from "../../components/LoadingScreen";
import useMediaQuery from "../../hooks/ueMediaQuery";
import { MobileSidebar } from "../../components/MobileSideBar";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useGetAppUser } from "../../hooks/useGetAppUser";

const S_DesktopLayout = styled(Box)`
  background: rgba(251, 251, 250, 1);
  display: flex;
  align-items: center;
  width: inherit;
`;

export const Layout = ({
  children,
}: {
  children: ReactElement<any, any>;
}): JSX.Element => {
  const isDesktop = useMediaQuery("(min-width:650px)");
  const [showMobileSidebar, setShowMobileSidebar] = useState<boolean>(false);
  const navigate = useNavigate();

  const { appUser, isFetchingAppUser } = useGetAppUser();

  const onLogOut = () => {
    return navigate("/logout");
  };

  if (isFetchingAppUser) {
    <LoadingScreen />;
  }

  return (
    <>
      {!isFetchingAppUser && !appUser?.id ? (
        <ErrorPage error={"Cannot find user."} />
      ) : (
        <>
          <AppBar
            onLogOut={onLogOut}
            appUser={appUser}
            onHamburgerClick={() => setShowMobileSidebar(!showMobileSidebar)}
          />
          {isDesktop ? (
            <S_DesktopLayout>{children}</S_DesktopLayout>
          ) : (
            <MobileSidebar
              onLogOut={onLogOut}
              onClose={() => setShowMobileSidebar(false)}
              children={children}
              appUser={appUser}
              isOpen={showMobileSidebar}
            />
          )}
        </>
      )}
    </>
  );
};
