import styled from "styled-components";
import React, { CSSProperties, useState } from "react";
import { AppUserViewModel, AppUserAvatar } from "../types";
import { useGetAvatarByIdQUERY } from "../hooks/useGetAppUserAvatars";

const StyledAvatar = styled.div<{
  size: "small" | "medium";
}>`
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: ${({ size }) => (size === "medium" ? "18px" : "10px")};
  font-weight: bold;

  /* Colors */
  color: #fff;

  /* Rounded border */
  border-radius: 50%;
  height: ${({ size }) => (size === "medium" ? "48px" : "24px")};
  width: ${({ size }) => (size === "medium" ? "48px" : "24px")};
`;

export const Avatar = ({
  size,
  appUser,
  onClick,
  style,
  isClickable,
}: {
  size: "small" | "medium";
  appUser?: AppUserViewModel;
  onClick?: (appUser: AppUserViewModel) => void;
  style?: CSSProperties;
  isClickable?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const userNameInitials = appUser?.name
    ?.split(" ")
    .map((x) => x[0])
    .join("");

  const getHashOfString = (str?: string) => {
    let hash = 0;
    if (str) {
      for (let i = 0; i < str?.length; i++) {
        hash = str?.charCodeAt(i) + ((hash << 5) - hash);
      }
      hash = Math.abs(hash);
      return hash;
    }
  };

  const normalizeHash = (hash?: number, min?: number, max?: number) => {
    // @ts-ignore
    return Math.floor((hash % (max - min)) + min);
  };

  const generateHSL = (name?: string) => {
    const hRange = [0, 360];
    const sRange = [50, 75];
    const lRange = [25, 60];

    const hash = getHashOfString(name);

    const h = normalizeHash(hash, hRange[0], hRange[1]);
    const s = normalizeHash(hash, sRange[0], sRange[1]);
    const l = normalizeHash(hash, lRange[0], lRange[1]);

    return HSLtoString([h, s, l]);
  };

  const HSLtoString = (hsl: any[]) => {
    return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
  };

  let avatarSource = "";
  const avatar = useGetAvatarByIdQUERY(appUser!.id);
  if (appUser?.appUserAvatarId) {
    avatarSource = `data:image/png;base64,${avatar?.imageUrl}`;
  }

  return appUser?.appUserAvatarId ? (
    <div
      data-testid="user-avatar"
      // @ts-ignore
      onClick={() => (isClickable ? onClick(appUser) : null)}
      style={{
        borderRadius: "50%",
        height: size === "medium" ? "48px" : "24px",
        width: size === "medium" ? "48px" : "24px",
        backgroundImage: `url(${avatarSource})`,
        backgroundRepeat: "round",
        zIndex: 2,
        cursor: isClickable ? "pointer" : "default",
        ...style,
      }}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    />
  ) : (
    <>
      <StyledAvatar
        data-testid="user-avatar"
        // @ts-ignore
        onClick={() => (isClickable ? onClick(appUser) : null)}
        size={size}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        style={{
          backgroundColor: generateHSL(appUser?.name),
          zIndex: 2,
          cursor: isClickable ? "pointer" : "default",
          ...style,
        }}
      >
        {!isHovering ? userNameInitials : ""}
      </StyledAvatar>
    </>
  );
};
