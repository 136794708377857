import React, { useEffect, useState } from "react";
import { useAuth } from "oidc-react";
import { Spinner } from "grommet";
import { PremierLeagueLogoWhite } from "../../assets/PremierLeagueLogoWhite";

export const Logout = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(true);

  const auth = useAuth();

  useEffect(() => {
    auth.signOut().then(() => {
      setIsLoggingOut(false);
    });
  }, []);

  if (isLoggingOut) {
    return <Spinner />;
  }

  return (
    <div
      style={{
        margin: "auto",
        width: "50%",
        padding: "10px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <PremierLeagueLogoWhite size="large" />
      <p>
        <h4>You have successfully logged out.</h4>
      </p>
      <a href="/">Go to the Dashboard</a>
    </div>
  );
};
