import React from "react";
import { Grommet } from "grommet";
import axios from "axios";
import env from "@beam-australia/react-env";

import { theme } from "./assets/theme";

import { AuthProvider } from "oidc-react";

import { AuthContext, oidcConfig } from "./utils/authUtils";
import { AllRoutes } from "./routes/AllRoutes";

export const APPBAR_HEIGHT = "84px";

axios.defaults.baseURL = env("API_URL");

const App = () => {
  return (
    <AuthProvider {...oidcConfig}>
      <AuthContext>
        <Grommet theme={theme}>
          <AllRoutes />
        </Grommet>
      </AuthContext>
    </AuthProvider>
  );
};

export default App;
