import { Box } from "grommet";
import React from "react";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { Avatar } from "./Avatar";
import { AppUserViewModel } from "../types";

const NameBox = styled.div`
  display: flex;
  color: ${() => colors.plMagenta06};
  padding-left: 5px;
  justify-content: start;
  font-size: 20px;
`;

const StyledDiv = styled.div`
  justify-content: start;
  font-size: 15px;
  display: flex;
  text-align: start;
  padding-left: 5px;
  color: ${() => colors.plDarkPurple06};
`;

export const AvatarHeader = ({
  appUser,
  extended,
}: {
  appUser?: AppUserViewModel;
  extended?: boolean;
}) => (
  <Box direction="row" align="center" background="transparent">
    <Avatar size="medium" appUser={appUser} />
    <Box direction="column" justify="start" pad={{ left: "6px" }}>
      <NameBox>{appUser?.name.replace(/[.]/g, " ")}</NameBox>
      <StyledDiv>{appUser?.userRole}</StyledDiv>
      {extended ? (
        <>
          <StyledDiv style={{ fontWeight: "bold" }}>{appUser?.email}</StyledDiv>
        </>
      ) : null}
    </Box>
  </Box>
);
