import axios from "axios";

export default class AppUserAvatarService {
  getAllAvatars = async () => {
    return await axios.get(`/app-user-avatar`).then(async (res) => {
      if (res.data) {
        return res.data;
      }
      return [];
    });
  };

  getAvatarById = async (id: string) => {
    return await axios.get(`/app-user-avatar/${id}`).then(async (res) => {
      if (res.data) {
        console.log("API called");
        return res.data;
      }
      return [];
    });
  };
}
