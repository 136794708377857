import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { colors } from "../../../assets/theme";
import { Box } from "grommet";

export const Stroller35 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="35px"
      height="35px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,17.5px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,17.5px)"
            : "unset",
        background: colors.plBlue1,
      }}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_2)">
          <path
            d="M18.6667 25.6667C19.9553 25.6667 21 24.622 21 23.3333C21 22.0447 19.9553 21 18.6667 21C17.378 21 16.3333 22.0447 16.3333 23.3333C16.3333 24.622 17.378 25.6667 18.6667 25.6667Z"
            fill="black"
            fillOpacity="0.64"
          />
          <path
            d="M7.00001 25.6667C8.28867 25.6667 9.33334 24.622 9.33334 23.3333C9.33334 22.0447 8.28867 21 7.00001 21C5.71134 21 4.66667 22.0447 4.66667 23.3333C4.66667 24.622 5.71134 25.6667 7.00001 25.6667Z"
            fill="black"
            fillOpacity="0.64"
          />
          <path
            d="M25.6667 8.16667V7.56C25.6667 5.32 23.94 3.5 21.7583 3.5C19.8217 3.5 18.795 4.98167 18.0483 5.86833L7.78167 17.9083C7.14001 18.6667 7.67667 19.8333 8.66834 19.8333H17.5C18.7833 19.8333 19.8333 18.7833 19.8333 17.5V7.315C20.51 6.52167 20.965 5.83333 21.7583 5.83333C22.6567 5.83333 23.3333 6.60333 23.3333 7.56V8.16667H25.6667Z"
            fill="black"
            fillOpacity="0.64"
          />
          <path
            d="M18.1767 5.28333C16.695 4.46667 14.98 4 13.16 4C10.8617 4 8.73833 4.74667 7 6.00667L12.705 11.7117L18.1767 5.28333Z"
            fill="black"
            fillOpacity="0.64"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_2">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
