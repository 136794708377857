import axios from "axios";
import { sub } from "date-fns";
import { dateFormatter } from "../utils";
import { LeaveType, OccurrenceType } from "../enums";
import { Occurrence } from "../types";

export default class OccurrenceService {
  getOccurrencesByTeamId = async (payload: {
    teamId: string;
    from_date: Date | number;
    to_date: Date | number;
  }) => {
    const { to_date, from_date, teamId } = payload;

    // remove one day because for some reason odata's ge and eq are not working
    // and it would not fetch the first occurrence of the query
    const fromDateLessOneDay = sub(new Date(from_date), { days: 1 });

    const fromDateFormatted = dateFormatter(
      new Date(fromDateLessOneDay as unknown as Date).toISOString()
    );
    const toDateFormatted = dateFormatter(
      new Date(to_date as unknown as Date).toISOString()
    );

    return await axios
      .get(
        `/occurrences?$filter=(teamId eq ${teamId} 
        and occurrence_date gt ${fromDateFormatted} 
        and occurrence_date le ${toDateFormatted})`
      )
      .then((res) => {
        return res.data.map((occ: Occurrence) => {
          // cast legacy brunel into londonbrunel
          if (occ.occurrenceType === OccurrenceType.brunel) {
            occ.occurrenceType = OccurrenceType.londonbrunel.replace(
              /\s/g,
              ""
            ) as OccurrenceType;
          }

          // cast legacy holiday into leave
          if (occ.occurrenceType === OccurrenceType.holiday) {
            occ.occurrenceType = OccurrenceType.leave;
            occ.description = LeaveType.annualLeave;
          }

          return occ;
        });
      });
  };

  getOccurrencesByDepartmentId = async (payload: {
    departmentId: string;
    from_date: Date;
    to_date: Date;
  }) => {
    const { to_date, from_date, departmentId } = payload;

    // remove one day because for some reason odata's ge and eq are not working
    // and it would not fetch the first occurrence of the query
    const fromDateLessOneDay = sub(new Date(from_date), { days: 1 });

    const fromDateFormatted = dateFormatter(
      new Date(fromDateLessOneDay as unknown as Date).toISOString()
    );
    const toDateFormatted = dateFormatter(
      new Date(to_date as unknown as Date).toISOString()
    );

    return await axios
      .get(
        `/occurrences?$filter=(departmentId eq ${departmentId} 
        and occurrence_date gt ${fromDateFormatted} 
        and occurrence_date le ${toDateFormatted})`
      )
      .then((res) => {
        return res.data.map((occ: Occurrence) => {
          // cast legacy brunel into londonbrunel
          if (occ.occurrenceType === OccurrenceType.brunel) {
            occ.occurrenceType = OccurrenceType.londonbrunel.replace(
              /\s/g,
              ""
            ) as OccurrenceType;
          }

          // cast legacy holiday into leave
          if (occ.occurrenceType === OccurrenceType.holiday) {
            occ.occurrenceType = OccurrenceType.leave;
            occ.description = LeaveType.annualLeave;
          }

          return occ;
        });
      });
  };

  getOccurrencesByUserIDs = async (payload: {
    userIds: string[];
    from_date: Date;
    to_date: Date;
  }) => {
    const { to_date, from_date, userIds } = payload;

    // remove one day because for some reason odata's ge and eq are not working
    // and it would not fetch the first occurrence of the query
    const fromDateLessOneDay = sub(new Date(from_date), { days: 1 });

    const fromDateFormatted = dateFormatter(
      new Date(fromDateLessOneDay as unknown as Date).toISOString()
    );
    const toDateFormatted = dateFormatter(
      new Date(to_date as unknown as Date).toISOString()
    );

    const userOrQuery = userIds.map((id) => `appUserId eq ${id}`).join(" or ");

    return await axios
      .get(
        `/occurrences?$filter=(occurrence_date gt ${fromDateFormatted}
        and occurrence_date le ${toDateFormatted} and (${userOrQuery}))`
      )
      .then((res) => {
        return res.data.map((occ: Occurrence) => {
          // cast legacy brunel into londonbrunel
          if (occ.occurrenceType === OccurrenceType.brunel) {
            occ.occurrenceType = OccurrenceType.londonbrunel.replace(
              /\s/g,
              ""
            ) as OccurrenceType;
          }

          // cast legacy holiday into leave
          if (occ.occurrenceType === OccurrenceType.holiday) {
            occ.occurrenceType = OccurrenceType.leave;
            occ.description = LeaveType.annualLeave;
          }

          return occ;
        });
      });
  };

  getOccurrencesByUserId = async (payload: {
    userId: string;
    from_date: Date;
    to_date: Date;
  }) => {
    const { to_date, from_date, userId } = payload;

    // remove one day because for some reason odata's ge and eq are not working
    // and it would not fetch the first occurrence of the query
    const fromDateLessOneDay = sub(new Date(from_date), { days: 1 });

    const fromDateFormatted = dateFormatter(
      new Date(fromDateLessOneDay as unknown as Date).toISOString()
    );
    const toDateFormatted = dateFormatter(
      new Date(to_date as unknown as Date).toISOString()
    );

    const occs = await axios
      .get(
        `/occurrences?$filter=(appUserId eq ${userId}
        and occurrence_date gt ${fromDateFormatted} 
        and occurrence_date le ${toDateFormatted})`
      )
      .then((res) => {
        return res.data.map((occ: Occurrence) => {
          // cast legacy brunel into londonbrunel
          if (occ.occurrenceType === OccurrenceType.brunel) {
            occ.occurrenceType = OccurrenceType.londonbrunel.replace(
              /\s/g,
              ""
            ) as OccurrenceType;
          }

          // cast legacy holiday into leave
          if (occ.occurrenceType === OccurrenceType.holiday) {
            occ.occurrenceType = OccurrenceType.leave;
            occ.description = LeaveType.annualLeave;
          }

          return occ;
        });
      });

    return occs;
  };

  deleteOccurrenceById = async (payload: {
    appUserId: string;
    userId: string;
    occurrences: {
      eventId: string;
      occurrenceId: string;
    }[];
  }) => {
    return axios
      .delete(`/occurrences`, { data: payload })
      .then((data) => console.log("success", data));
  };
}
