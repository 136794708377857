import React, { CSSProperties, ReactNode } from "react";
import { OccurrenceType } from "../enums";
import { Box, RadioButton } from "grommet";
import styled from "styled-components";
import { IconBox } from "./UpcomingOccurrences";
import { colors } from "../assets/theme";
import { capitalizeFirstLetter } from "../utils";

export const EventOptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  min-height: min-content;
  padding: 0px 10px;
`;

export const EventLabel = styled.span`
  font-size: 16px;
  line-height: 23px;
  text-align: left !important;
  color: ${() => colors.plDarkPurple};
  margin-left: 15px;
`;

export const EventOption = ({
  icon,
  label,
  onClick,
  eventType,
  disabled,
  style,
}: {
  icon?: ReactNode;
  label: OccurrenceType;
  onClick: (label: OccurrenceType) => void;
  eventType: OccurrenceType | null;
  disabled: boolean;
  style?: CSSProperties;
}) => {
  return (
    <EventOptionContainer style={style}>
      <Box direction="row" align="center">
        {icon ? <IconBox>{icon}</IconBox> : null}
        <EventLabel>{capitalizeFirstLetter(label)}</EventLabel>
      </Box>
      <RadioButton
        disabled={disabled}
        name={label}
        onChange={() => onClick(label)}
        checked={eventType === label}
      />
    </EventOptionContainer>
  );
};
