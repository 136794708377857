import { useEffect, useState } from "react";
import { AppUserViewModel } from "../types";
import AppUserService from "../services/appUserService";
import { useGetAppUser } from "./useGetAppUser";

export const useFetchDepartmentUsers = () => {
  const [allUsers, setAllUsers] = useState<AppUserViewModel[]>([]);
  const [isFetchingAllUsers, setIsFetchingAllUsers] = useState<boolean>(false);
  const { appUser } = useGetAppUser();
  const appUserService = new AppUserService();

  useEffect(() => {
    if (appUser) {
      const fetchAllUsers = async () => {
        setIsFetchingAllUsers(true);
        const allUsersData = await appUserService.getAllUsers();

        const onlyDeptUsers = allUsersData
          .filter(
            (user: AppUserViewModel) =>
              user.departmentId === appUser?.departmentId
          )
          .map((user: AppUserViewModel) => ({
            ...user,
            name: user.name.replace(/[.]/g, " ").replace(/^(ext)/gi, ""),
          }));

        setAllUsers(onlyDeptUsers);
        setIsFetchingAllUsers(false);
      };

      fetchAllUsers();
    }
  }, [appUser]);

  return { allUsers, isFetchingAllUsers };
};
