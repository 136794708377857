import styled, { css } from "styled-components";
import { Box } from "grommet";
import React, { ReactElement } from "react";
import { Icon, IconProps } from "grommet-icons";
import { Link } from "react-router-dom";
import { colors } from "../assets/theme";
import { AppUserViewModel } from "../types";
import useMediaQuery from "../hooks/ueMediaQuery";

const active = css`
  color: ${() => colors.plMagenta1};
  font-weight: bold;
`;

const StyledSpan = styled.span<{
  activeRoute?: boolean;
  isDesktop?: boolean;
}>`
  font-weight: normal;
  text-decoration: none !important;
  width: 100%;
  margin-left: 5px;
  ${({ activeRoute }) => (activeRoute ? active : null)}
  :hover {
    font-weight: bold;
  }
`;

const StyledTab = styled(Box)<{ activeRoute?: boolean }>`
  background-color: ${({ activeRoute }) =>
    activeRoute ? colors.plMagenta01 : "unset"};
  height: 60px;
  padding: 0 20px;
`;

type SideBarLinkProps = {
  label?: string;
  navigateTo: string;
  icon: ReactElement<IconProps, Icon>;
  isActive: boolean;
  appUser?: AppUserViewModel;
  onClick: () => void;
};

export const SideBarLink = ({
  label,
  icon,
  navigateTo,
  appUser,
  isActive,
  onClick,
}: SideBarLinkProps) => {
  const isDesktop = useMediaQuery("(min-width:550px)");

  return (
    <Link
      to={navigateTo}
      state={{ appUser: appUser }}
      style={{
        textDecoration: "none",
      }}
      onClick={onClick}
    >
      <StyledTab
        activeRoute={isActive}
        direction="row"
        align="center"
        justify="center"
      >
        <>{icon}</>
        <div>
          <StyledSpan
            isDesktop={isDesktop}
            activeRoute={isActive}
            color={colors.plDarkPurple06}
          >
            {label}
          </StyledSpan>
        </div>
      </StyledTab>
    </Link>
  );
};
