import { LeaveType, OccurrenceDuration, OccurrenceType } from "./enums";
import React from "react";
import styled, { css } from "styled-components";
import { clubLogos } from "./assets/club_logos/48";
import { Text } from "grommet";
import { colors } from "./assets/theme";
import { Leave35 } from "./components/svgs/Leave/Leave35";
import { Leave20 } from "./components/svgs/Leave/Leave20";
import {
  AppUserViewModel,
  DateInterval,
  Day,
  Occurrence,
  OccurrenceViewModel,
} from "./types";
import { add, format } from "date-fns";
import { Stroller20 } from "./components/svgs/Stroller/Stroller20";
import { Stroller35 } from "./components/svgs/Stroller/Stroller35";
import { Sick20 } from "./components/svgs/Sick/Sick20";
import { Sick35 } from "./components/svgs/Sick/Sick35";
import { Travel35 } from "./components/svgs/Travel/Travel35";
import { Travel20 } from "./components/svgs/Travel/Travel20";

const ColoredBox = styled(Text)<{
  occurrence: {
    type:
      | OccurrenceType.brunel
      | OccurrenceType.remote
      | OccurrenceType.londonbrunel
      | OccurrenceType.newyork
      | OccurrenceType.singapore;
    duration: OccurrenceDuration;
  };
  iconSize: string;
  iconFullWidth?: boolean;
  showFullSizedBox?: boolean;
}>`
  position: absolute;
  left: ${({ occurrence, iconFullWidth, showFullSizedBox }) => {
    if (iconFullWidth || showFullSizedBox) {
      return occurrence.duration === OccurrenceDuration.am ? 0 : "unset";
    }
    return occurrence.duration === OccurrenceDuration.am ? "7.5px" : "unset";
  }};
  right: ${({ occurrence, iconFullWidth, showFullSizedBox }) => {
    if (iconFullWidth || showFullSizedBox) {
      return occurrence.duration === OccurrenceDuration.pm ? 0 : "unset";
    }
    return occurrence.duration === OccurrenceDuration.pm ? "7.5px" : "unset";
  }};
  width: ${({ occurrence, iconSize, showFullSizedBox }) => {
    let iconSizeInRow = showFullSizedBox ? "35" : iconSize;
    return occurrence.duration === OccurrenceDuration.fd
      ? `${+iconSizeInRow}px`
      : `${+iconSizeInRow / 2}px`;
  }};
  height: ${({ iconSize, showFullSizedBox }) => {
    let iconSizeInRow = showFullSizedBox ? "35" : iconSize;

    return `${+iconSizeInRow}px`;
  }};
  background: ${({ occurrence }) =>
    occurrence.type === OccurrenceType.brunel ||
    occurrence.type === OccurrenceType.londonbrunel.replace(/\s/g, "") ||
    occurrence.type === OccurrenceType.newyork.replace(/\s/g, "") ||
    occurrence.type === OccurrenceType.singapore
      ? colors.brunelPurple
      : colors.remoteYellow};
`;

const overlay35 = (color: string) => css`
  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    display: block;
    background: ${color};
  }
`;

const todaySidesBorder = () => css`
  border-right: ${() => `2px solid ${colors.plGreen}`};
  border-left: ${() => `2px solid ${colors.plGreen}`};
`;

export const RowEventBox = styled.div<{
  strong?: boolean;
  greyOverlay?: boolean;
  greenSideBorder?: boolean;
  clickable?: boolean;
  isLastChild?: boolean;
}>`
  justify-content: center;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
  display: flex;
  position: relative;
  align-items: center;
  min-width: 35px;
  border-bottom: ${({ isLastChild }) =>
    !isLastChild ? `0.5px solid rgba(235, 235, 230)` : undefined};
  min-height: 35px;
  font-weight: ${({ strong }) => (strong ? "bold" : null)};
  font-size: 16px;
  color: ${() => colors.plDarkPurple};
  :hover {
    ${() => overlay35(colors.hoveringGrey)}
  }
  ${({ greyOverlay }) => (greyOverlay ? overlay35(colors.weekendGrey) : null)}
  ${({ greenSideBorder }) => (greenSideBorder ? todaySidesBorder() : null)}
`;

export const capitalizeFirstLetter = (string: string): string => {
  const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  if (!string) {
    return "";
  }

  return string?.length > 1
    ? string
        .split(" ")
        .map((s) => capitalize(s))
        .join(" ")
        .replace(/fc/gi, "FC")
    : string.constructor === String
    ? capitalize(string)
    : "";
};

export const getOrdinalSuffixOf = (number: number) => {
  const j = number % 10,
    k = number % 100;
  if (j === 1 && k !== 11) {
    return number + "st";
  }
  if (j === 2 && k !== 12) {
    return number + "nd";
  }
  if (j === 3 && k !== 13) {
    return number + "rd";
  }
  return number + "th";
};

export const isWeekend = (date: Date) =>
  new Date(date).getDay() === 6 || new Date(date).getDay() === 0;

export const isToday = (date: Date) =>
  new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

export const getIconForInteractiveBox = (
  day: Day,
  iconSize: string,
  showNumbers?: boolean,
  iconFullWidth?: boolean,
  isRow?: boolean
) => {
  const noClubEvents = !day?.occurrences?.every(
    (occurrence) =>
      occurrence.type !== OccurrenceType.remote &&
      occurrence.type !== OccurrenceType.brunel &&
      occurrence.type !== OccurrenceType.londonbrunel.replace(/\s/g, "") &&
      occurrence.type !== OccurrenceType.newyork.replace(/\s/g, "") &&
      occurrence.type !== OccurrenceType.singapore &&
      occurrence.type !== OccurrenceType.parentalleave.replace(/\s/g, "") &&
      occurrence.type !== OccurrenceType.sickleave.replace(/\s/g, "") &&
      occurrence.type !== OccurrenceType.leave &&
      occurrence.type !== OccurrenceType.travel
  );

  let showFullSizedBox = false;
  if (isRow && noClubEvents) {
    showFullSizedBox = true;
  }
  return day?.occurrences?.map((occ, i) => {
    let occurrence = occ;

    const sameOccurrenceTypeButDifferentEvent =
      day.occurrences.length === 2 &&
      day.occurrences[0].type === day.occurrences[1].type;
    const isFullDay = occurrence.duration === OccurrenceDuration.fd;
    const isAm = occurrence.duration === OccurrenceDuration.am;
    const isPmAndOnlyOccurrence =
      occurrence.duration === OccurrenceDuration.pm &&
      day.occurrences.length === 1;
    const isAmAndOnlyOccurrence =
      occurrence.duration === OccurrenceDuration.am &&
      day.occurrences.length === 1;
    const eitherRemoteOrOffice =
      day.occurrences.length === 2 &&
      day.occurrences.every(
        (occ) =>
          occ.type === OccurrenceType.brunel ||
          occ.type === OccurrenceType.londonbrunel.replace(/\s/g, "") ||
          occ.type === OccurrenceType.singapore ||
          occ.type === OccurrenceType.newyork.replace(/\s/g, "") ||
          occ.type === OccurrenceType.remote
      );

    switch (true) {
      case !occurrence.type:
        return showNumbers ? (
          <Text
            key={`${occurrence.type}${i}`}
            weight="bold"
            textAlign="center"
            size="small"
            color="black"
          >
            {day.number}
          </Text>
        ) : null;
      case occurrence.type === OccurrenceType.brunel:
      case occurrence.type === OccurrenceType.londonbrunel.replace(/\s/g, ""):
      case occurrence.type === OccurrenceType.singapore:
      case occurrence.type === OccurrenceType.newyork.replace(/\s/g, ""):
      case occurrence.type === OccurrenceType.remote:
        const shouldShowDayNumber = () => {
          return isFullDay ||
            (isAm && eitherRemoteOrOffice) ||
            isPmAndOnlyOccurrence ||
            isAmAndOnlyOccurrence
            ? day.number
            : "";
        };
        return (
          <ColoredBox
            showFullSizedBox={showFullSizedBox}
            iconFullWidth={iconFullWidth}
            // @ts-ignore
            occurrence={occurrence}
            iconSize={iconSize}
            key={`${occurrence.type}${i}`}
            weight="bold"
            textAlign="center"
            size="small"
            color="black"
          >
            {showNumbers ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: 2,
                  left: isPmAndOnlyOccurrence ? "-10px" : "unset",
                }}
              >
                {shouldShowDayNumber()}
              </div>
            ) : null}
          </ColoredBox>
        );
      case occurrence.type === OccurrenceType.leave:
      // legacy
      case occurrence.type === OccurrenceType.holiday:
        return iconSize === "35" || (iconSize === "20" && showFullSizedBox) ? (
          <Leave35
            leaveType={occurrence.description as LeaveType}
            key={`${occurrence.type}${i}`}
            // if the duration is different from FD
            // we need "position: absolute" to clip the icon in the middle
            // and show the double icon
            positionAbsolute
            occurrenceDuration={occurrence.duration}
          />
        ) : (
          <Leave20
            leaveType={occurrence.description as LeaveType}
            key={`${occurrence.type}${i}`}
            positionAbsolute
            occurrenceDuration={occurrence.duration}
          />
        );
      case occurrence.type === OccurrenceType.parentalleave.replace(/\s/g, ""):
        return iconSize === "35" || (iconSize === "20" && showFullSizedBox) ? (
          <Stroller35
            positionAbsolute
            key={`${occurrence.type}${i}`}
            // if the duration is different from FD
            // we need "position: absolute" to clip the icon in the middle
            // and show the double icon
            occurrenceDuration={occurrence.duration}
          />
        ) : (
          <Stroller20
            positionAbsolute
            key={`${occurrence.type}${i}`}
            occurrenceDuration={occurrence.duration}
          />
        );
      case occurrence.type === OccurrenceType.travel.replace(/\s/g, ""):
        return iconSize === "35" || (iconSize === "20" && showFullSizedBox) ? (
          <Travel35
            positionAbsolute
            key={`${occurrence.type}${i}`}
            // if the duration is different from FD
            // we need "position: absolute" to clip the icon in the middle
            // and show the double icon
            occurrenceDuration={occurrence.duration}
          />
        ) : (
          <Travel20
            positionAbsolute
            key={`${occurrence.type}${i}`}
            occurrenceDuration={occurrence.duration}
          />
        );
      case occurrence.type === OccurrenceType.sickleave.replace(/\s/g, ""):
        return iconSize === "35" || (iconSize === "20" && showFullSizedBox) ? (
          <Sick35
            positionAbsolute
            key={`${occurrence.type}${i}`}
            // if the duration is different from FD
            // we need "position: absolute" to clip the icon in the middle
            // and show the double icon
            occurrenceDuration={occurrence.duration}
          />
        ) : (
          <Sick20
            positionAbsolute
            key={`${occurrence.type}${i}`}
            occurrenceDuration={occurrence.duration}
          />
        );
      default:
        return (
          <img
            key={`${occurrence.type}${i}`}
            style={{
              position: "absolute",
              clip:
                occurrence.duration === OccurrenceDuration?.am
                  ? `rect(auto,${+iconSize / 2}px,auto,auto)`
                  : occurrence.duration === OccurrenceDuration?.pm
                  ? `rect(auto,auto,auto,${+iconSize / 2}px)`
                  : "unset",
            }}
            // @ts-ignore
            src={clubLogos[occurrence.type.replace(/\s/g, "")]}
            height={iconSize}
            width={
              isFullDay || sameOccurrenceTypeButDifferentEvent
                ? undefined
                : iconSize
            }
            alt={occurrence?.type?.toString()}
          />
        );
    }
  });
};

export const weekdaysArray = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthsArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const weekDaysToNumber = (weekDay: string) => {
  switch (true) {
    case weekDay === "sunday":
      return 0;
    case weekDay === "monday":
      return 1;
    case weekDay === "tuesday":
      return 2;
    case weekDay === "wednesday":
      return 3;
    case weekDay === "thursday":
      return 4;
    case weekDay === "friday":
      return 5;
    case weekDay === "saturday":
      return 6;
    default:
      return;
  }
};

export const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

export const createDatesInterval = (
  interval: number,
  startDate?: Date
): DateInterval[] => {
  const datesInterval = [] as DateInterval[];
  const date = startDate ?? new Date();

  for (let i = 0; i < interval; i++) {
    datesInterval.push({
      number: add(date, { days: i }).getDate(),
      weekDay: weekdaysArray[add(date, { days: i }).getDay()].toLowerCase(),
      monthString: monthsArray[add(date, { days: i }).getMonth()].toLowerCase(),
      monthNumber: add(date, { days: i }).getMonth(),
      date: add(date, { days: i }),
    });
  }
  return datesInterval;
};

export const sortWithUsersEntityFirst = (
  referenceId: string,
  referenceArr: any[]
) => {
  let arrCopy = [...referenceArr];
  const referenceItem = arrCopy?.find((item) => item.id === referenceId);
  const referenceItemIndex = arrCopy?.findIndex(
    (item) => item.id === referenceId
  );

  if (referenceItemIndex > -1) {
    arrCopy?.splice(referenceItemIndex, 1);
  }

  return referenceItemIndex > -1
    ? [
        referenceItem,
        ...arrCopy?.sort((a: any, b: any) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        ),
      ]
    : [
        ...arrCopy?.sort((a: any, b: any) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        ),
      ];
};

// RUN THE WHOLE TIME INTERVAL TO GENERATE DAYS WITH OCCURRENCES
export const generateDays = (
  datesInterval: DateInterval[],
  userOccurrences?: Occurrence[]
) => {
  return datesInterval.map((day) => {
    const occurrencesForDay = userOccurrences
      ?.filter((occ) => {
        // CAST OCCURRENCE DATE INTO JS DATE
        const occurrenceDate = new Date(occ.occurrence_date as unknown as Date);
        // GET THE OCCURRENCES THAT EXIST FOR A GIVEN DAY
        return (
          occurrenceDate.getDate() === day.number &&
          occurrenceDate.getMonth() === day.monthNumber
        );
      })
      // AND MAP THEM INTO THE OCCURRENCE STRUCTURE WE NEED
      .map((occurrence) => ({
        id: occurrence.id,
        type: occurrence.occurrenceType,
        duration: occurrence.session_period,
        appUserId: occurrence.appUserId,
        eventId: occurrence.eventId,
        description: occurrence.description,
      }))
      .sort((a: OccurrenceViewModel, b: OccurrenceViewModel) =>
        a.duration < b.duration ? -1 : a.duration > b.duration ? 1 : 0
      );

    return {
      date: dateFormatter(new Date(day.date).toISOString()),
      number: day.number,
      weekDay: day.weekDay,
      month: day.monthString,
      // RETURN ARR OF OCCURRENCES FOR THE DAY, OR JUST AN EMPTY OCCURRENCE
      occurrences:
        occurrencesForDay && occurrencesForDay?.length > 0
          ? occurrencesForDay
          : [
              {
                type: null,
                duration: OccurrenceDuration.fd,
              },
            ],
    };
  });
};

export const getAppUsersWithDays = (
  appUserId: string,
  appUsers: AppUserViewModel[],
  datesInterval: DateInterval[],
  occurrences?: Occurrence[]
) => {
  return appUsers.map((user) => {
    const userOccurrences = occurrences?.filter(
      (occ) => occ.appUserId === user.id
    );

    const days = generateDays(datesInterval, userOccurrences);

    return {
      ...user,
      days,
    };
  });
};

export const canManage = (
  manager: AppUserViewModel,
  employee: AppUserViewModel,
  users: AppUserViewModel[]
) => {
  const appUser = users.find((u) => u.id === manager.id);

  if (!appUser) {
    return;
  }

  if (appUser.id === employee.id) {
    return true; // the user can manage themselves
  }

  const queue: string[] = [];
  const visited: Set<string> = new Set();

  queue.push(appUser.id);

  while (queue.length > 0) {
    const currentUserId = queue.shift()!;
    visited.add(currentUserId);
    const currentUser = users.find((u) => u.id === currentUserId)!;

    if (currentUser) {
      // check if currentUser manages the employee or if the employee has delegated currentUser
      if (
        currentUser?.managerOf.includes(employee.id) ||
        employee.delegatesTo.flatMap((d) => d.id).includes(currentUser.id)
      ) {
        return true;
      }

      for (const subordinateId of currentUser?.managerOf) {
        if (!visited.has(subordinateId)) {
          queue.push(subordinateId);
        }
      }
    }
  }

  return false;
};

export const dateFormatter = (date: string) => {
  const dateLocale = format(new Date(date as unknown as Date), "P");
  const year = dateLocale.split("/")[2];
  const month = dateLocale.split("/")[0];
  const day = dateLocale.split("/")[1];

  return `${year}-${month}-${day}`;
};
