import React from "react";
import { OccurrenceDuration } from "../../../enums";

export const Office20 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => (
  <svg
    style={{
      position: positionAbsolute ? "absolute" : "unset",
      clip:
        occurrenceDuration === OccurrenceDuration?.am
          ? "rect(auto,10px,auto,auto)"
          : occurrenceDuration === OccurrenceDuration?.pm
          ? "rect(auto,auto,auto,10px)"
          : "unset",
    }}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="#9747FF" />
  </svg>
);
