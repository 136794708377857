import { ThemeType } from "grommet";

export const colors = {
  plGrey02: "rgba(118, 108, 108, 0.2)",
  plStone02: "rgba(251, 251, 250, 1)",
  plStone1: "rgba(235, 235, 230, 1)",
  plStone05: "rgba(235, 235, 230, 0.5)",
  plPurple: "rgba(125, 76, 219)",
  plGreen: "rgba(0, 255, 133, 1)",
  plPurple01: "rgba(125, 76, 219, 0.1)",
  plPurple06: "rgba(125, 76, 219, 0.6)",
  plMagenta06: "rgba(233, 0, 82, 0.6)",
  plMagenta1: "rgba(233, 0, 82, 1)",
  plMagenta01: "rgba(233, 0, 82, 0.1)",
  plDarkPurple: "rgba(55, 0, 60, 1)",
  plDarkPurple06: "rgba(55, 0, 60, 0.6)",
  plBlack: "rgba(51, 51, 51, 1)",
  plDarkGrey: "#CCCCCC",
  plWhite: "#ffffff",
  plBlue1: "rgba(4,245,255, 1)",
  remoteYellow: "rgba(235, 255, 0, 0.8)",
  brunelPurple: "rgba(151, 71, 255, 0.8)",
  weekendGrey: "rgba(236, 236, 236, 0.5)",
  hoveringGrey: "rgba(236, 236, 236, 0.3)",
  todayGreen: "rgba(0, 255, 133, 0.5)",
};

export const theme: ThemeType = {
  global: {
    font: {
      family: "Premier League",
      size: "16px",
      height: "20px",
    },
    colors: colors,
  },
  calendar: {},
  select: {
    background: colors.plPurple01,
  },
  textInput: {
    container: { extend: `text-align: center;` },
  },
  tip: {
    content: {
      background: "white",
      elevation: "small",
      margin: "xsmall",
      pad: "0",
      round: "xsmall",
    },
  },
  checkBox: {
    check: { extend: () => `border-color: ${colors.plDarkPurple06}` },
  },
  radioButton: {
    check: { extend: () => `border-color: ${colors.plDarkPurple06}` },
  },
  tab: {
    hover: {
      color: colors.plDarkPurple06,
    },
    border: {
      active: { color: colors.plPurple },
      color: "transparent",
      hover: { color: colors.plDarkPurple06 },
    },
    active: {
      color: colors.plPurple,
      background: colors.plPurple01,
    },
  },
  tabs: {},
};
