import { Avatar } from "./Avatar";
import React from "react";
import styled, { css } from "styled-components";
import { Box } from "grommet";
import { colors } from "../assets/theme";
import { AppUserViewModel } from "../types";
import useMediaQuery from "../hooks/ueMediaQuery";
import { AppUserInfoTooltip } from "./AppUserInfoTooltip";
import { Link } from "react-router-dom";

const NamesBadge = styled(Box)<{ isMobile?: boolean; isAppUser: boolean }>`
  cursor: pointer;
  align-items: center;
  font-weight: ${({ isAppUser }) => (isAppUser ? "bold" : "normal")};
  min-width: ${({ isMobile }) => (!isMobile ? "190px" : "unset")};
`;

const separator = css`
  :after {
    content: "";
    position: absolute;
    border-left: ${() => `2px solid ${colors.plStone05}`};
    right: -10px;
    height: 150%;
  }
`;

const NamesBox = styled("div")<{
  withSeparator?: boolean;
  dontTruncate?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: ${({ dontTruncate }) => (dontTruncate ? "fit-content" : "150px")};
  margin-right: 15px;
  flex-shrink: 0;
  ${({ withSeparator }) => (withSeparator ? separator : null)}
`;

const NameBox = styled.div<{ viewMode?: boolean }>`
  justify-content: center;
  display: inline-block;
  align-items: center;
  color: ${() => colors.plMagenta06};
  line-height: normal;
  margin-left: ${({ viewMode }) => (viewMode ? "15px" : "5px")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ viewMode }) => (viewMode ? "24px" : "16px")};
  :hover {
    text-decoration: underline;
  }
`;

export const AppUserBadge = ({
  appUser,
  withSeparator,
  appUserId,
  onClick,
  showPinOnHover,
  dontTruncate,
  viewMode,
}: {
  appUser: AppUserViewModel;
  withSeparator?: boolean;
  dontTruncate?: boolean;
  appUserId?: string;
  onClick?: (appUSer: AppUserViewModel) => void;
  showPinOnHover?: boolean;
  viewMode?: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return isDesktop ? (
    <NamesBadge
      isMobile={!isDesktop}
      isAppUser={appUser?.id === appUserId}
      direction="row"
      alignSelf="center"
    >
      <>
        <Avatar
          isClickable={showPinOnHover}
          // @ts-ignore
          onClick={() => onClick(appUser)}
          appUser={appUser}
          size={viewMode ? "medium" : "small"}
        />
        {isDesktop ? (
          <AppUserInfoTooltip appUser={appUser}>
            <Link
              to="/calendar"
              state={{ appUser: appUser }}
              style={{ textDecoration: "none" }}
            >
              <NamesBox
                withSeparator={withSeparator}
                dontTruncate={dontTruncate}
              >
                <NameBox viewMode={viewMode}>
                  {appUser?.name.replace(/[.]/g, " ")}
                </NameBox>
              </NamesBox>
            </Link>
          </AppUserInfoTooltip>
        ) : null}
      </>
    </NamesBadge>
  ) : (
    <NamesBadge
      isMobile={!isDesktop}
      isAppUser={appUser?.id === appUserId}
      direction="row"
      alignSelf="center"
      style={{ cursor: "pointer" }}
    >
      {/*@ts-ignore*/}
      <Avatar appUser={appUser} size="small" onClick={() => onClick(appUser)} />
      {isDesktop ? (
        <NamesBox withSeparator={withSeparator}>
          <NameBox>{appUser?.name.replace(/[.]/g, " ")}</NameBox>
        </NamesBox>
      ) : null}
    </NamesBadge>
  );
};
