import styled from "styled-components";
import { colors } from "../assets/theme";

const HamburgerContainer = styled.div`
  height: 35px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
`;
const HamburgerSegment = styled.div`
  height: 5px;
  background-color: ${() => colors.plPurple};
`;

export const Hamburger = ({ onClick }: { onClick: () => void }) => (
  <HamburgerContainer onClick={onClick} data-testid={"hamburger"}>
    <HamburgerSegment />
    <HamburgerSegment />
    <HamburgerSegment />
  </HamburgerContainer>
);
