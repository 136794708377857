import { AppUserSettings } from "../../types";
import {
  DashboardSettingsOptions,
  DefaultOffice,
  DefaultView,
} from "../../enums";
import { Box, Select } from "grommet";
import { capitalizeFirstLetter } from "../../utils";
import React from "react";
import { SettingItem } from "./shared/SettingItem";

export const DashboardSettings = ({
  settings,
  onUpdate,
}: {
  settings: AppUserSettings;
  onUpdate: (payload: Pick<AppUserSettings, DashboardSettingsOptions>) => void;
}) => {
  const onChange = async (
    dashboardSetting: DashboardSettingsOptions,
    value: any
  ) => {
    const settingsCopy = settings;

    settingsCopy[dashboardSetting] = value;

    await onUpdate(settingsCopy);
  };

  return (
    <>
      <SettingItem>
        <Box direction="column">
          <Box>Default View</Box>
        </Box>
        <Select
          style={{ height: "20px", width: "100px" }}
          size="small"
          options={Object.values(DefaultView).map((defaultView) =>
            capitalizeFirstLetter(defaultView)
          )}
          value={capitalizeFirstLetter(settings.defaultView)}
          onChange={(event) =>
            onChange(
              DashboardSettingsOptions.defaultView,
              event.target.value.toLowerCase()
            )
          }
        />
      </SettingItem>
    </>
  );
};
