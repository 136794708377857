import { Box, Text } from "grommet";
import { colors } from "../../assets/theme";
import { AppUserBadge } from "../AppUserBadge";
import React from "react";
import {
  SummaryCard,
  SummaryLabel,
  SummarySubtitle,
  SummarySubtitleLabel,
  SummaryTitle,
} from "./SummaryCards";
import { AppUserViewModel, TeamViewModel } from "../../types";
import { capitalizeFirstLetter } from "../../utils";
import { OccurrenceType } from "../../enums";

export const OfficeTodaySummaryCard = ({
  team,
  appUser,
  todayInOfficeUsers,
}: {
  team: TeamViewModel;
  appUser: AppUserViewModel;
  todayInOfficeUsers: {
    londonbrunel: (AppUserViewModel & { officeToday: string })[];
    newyork: (AppUserViewModel & { officeToday: string })[];
    singapore: (AppUserViewModel & { officeToday: string })[];
  };
}) => {
  const noOneInOffice = Object.values(todayInOfficeUsers).every(
    (officeUSer) => officeUSer.length === 0
  );

  const getOfficePeople = () =>
    Object.entries(todayInOfficeUsers).map((office) => {
      const [officeName, officeUSer] = office;

      return (
        officeUSer.length > 0 && (
          <Box key={officeName}>
            <SummarySubtitleLabel>
              <SummarySubtitle>
                {capitalizeFirstLetter(
                  OccurrenceType[officeName as keyof typeof OccurrenceType]
                )}
              </SummarySubtitle>
            </SummarySubtitleLabel>
            {officeUSer.map((user) => (
              <Box
                key={user.id}
                margin={{ bottom: "small", left: "medium" }}
                alignSelf="start"
              >
                <AppUserBadge
                  dontTruncate
                  appUser={user as AppUserViewModel}
                  appUserId={appUser.id}
                />
              </Box>
            ))}
          </Box>
        )
      );
    });

  return (
    <SummaryCard round={false} data-testid={"office-today"}>
      <SummaryLabel>
        <SummaryTitle>Office Today</SummaryTitle>
        <SummarySubtitle>{team.name}</SummarySubtitle>
      </SummaryLabel>
      <Box
        direction="column"
        align="center"
        alignSelf="start"
        justify="start"
        width="100%"
        pad="0 20px"
      >
        {noOneInOffice ? (
          <Box alignContent="center">
            <Text>There is nobody in the office today.</Text>
          </Box>
        ) : (
          <Box alignContent="left" width="100%">
            {getOfficePeople()}
          </Box>
        )}
      </Box>
    </SummaryCard>
  );
};
