import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { colors } from "../../../assets/theme";
import { Box } from "grommet";

export const Stroller20 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="20px"
      height="20px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,10px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,10px)"
            : "unset",
        background: colors.plBlue1,
      }}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3_26)">
          <path
            d="M10.6666 14.6667C11.403 14.6667 12 14.0697 12 13.3333C12 12.597 11.403 12 10.6666 12C9.93027 12 9.33331 12.597 9.33331 13.3333C9.33331 14.0697 9.93027 14.6667 10.6666 14.6667Z"
            fill="black"
            fillOpacity="0.58"
          />
          <path
            d="M3.99999 14.6667C4.73637 14.6667 5.33332 14.0697 5.33332 13.3333C5.33332 12.597 4.73637 12 3.99999 12C3.26361 12 2.66666 12.597 2.66666 13.3333C2.66666 14.0697 3.26361 14.6667 3.99999 14.6667Z"
            fill="black"
            fillOpacity="0.58"
          />
          <path
            d="M14.6667 4.66667V4.32C14.6667 3.04 13.68 2 12.4333 2C11.3267 2 10.74 2.84667 10.3133 3.35333L4.44665 10.2333C4.07999 10.6667 4.38665 11.3333 4.95332 11.3333H9.99999C10.7333 11.3333 11.3333 10.7333 11.3333 10V4.18C11.72 3.72667 11.98 3.33333 12.4333 3.33333C12.9467 3.33333 13.3333 3.77333 13.3333 4.32V4.66667H14.6667Z"
            fill="black"
            fillOpacity="0.58"
          />
          <path
            d="M9.86668 3.46667C9.02001 3 8.04001 2.73334 7.00001 2.73334C5.68668 2.73334 4.47334 3.16 3.48001 3.88L6.74001 7.14L9.86668 3.46667Z"
            fill="black"
            fillOpacity="0.58"
          />
        </g>
        <defs>
          <clipPath id="clip0_3_26">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
