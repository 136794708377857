import afcbournemouth from "./afcbournemouth.png";
import evertonfc from "./evertonfc.png";
import newcastleunited from "./newcastleunited.png";
import arsenalfc from "./arsenalfc.png";
import fulhamfc from "./fulhamfc.png";
import norwichfc from "./norwichfc.png";
import astonvillafc from "./astonvillafc.png";
import nottinghamforestfc from "./nottinghamforestfc.png";
import brentfordfc from "./brentfordfc.png";
import leedsunitedfc from "./leedsunitedfc.png";
import southamptonfc from "./southamptonfc.png";
import brightonandhovefc from "./brightonandhovefc.png";
import leicestercityfc from "./leicestercityfc.png";
import tottenhamhotspurfc from "./tottenhamhotspurfc.png";
import burnleyfc from "./burnleyfc.png";
import liverpoolfc from "./liverpoolfc.png";
import watfordfc from "./watfordfc.png";
import chelseafc from "./chelseafc.png";
import manchestercity from "./manchestercity.png";
import westhamunited from "./westhamunited.png";
import crystalpalacefc from "./crystalpalacefc.png";
import manchesterunited from "./manchesterunited.png";
import wolverhamptonfc from "./wolverhamptonfc.png";
import sheffieldunitedfc from "./sheffieldunitedfc.png";
import lutontownfc from "./lutontownfc.png";

export const clubLogos = {
  afcbournemouth: afcbournemouth,
  evertonfc: evertonfc,
  newcastleunited: newcastleunited,
  arsenalfc: arsenalfc,
  fulhamfc: fulhamfc,
  norwichfc: norwichfc,
  astonvillafc: astonvillafc,
  nottinghamforestfc: nottinghamforestfc,
  brentfordfc: brentfordfc,
  leedsunitedfc: leedsunitedfc,
  southamptonfc: southamptonfc,
  brightonandhovefc: brightonandhovefc,
  leicestercityfc: leicestercityfc,
  tottenhamhotspurfc: tottenhamhotspurfc,
  burnleyfc: burnleyfc,
  liverpoolfc: liverpoolfc,
  watfordfc: watfordfc,
  chelseafc: chelseafc,
  manchestercity: manchestercity,
  westhamunited: westhamunited,
  crystalpalacefc: crystalpalacefc,
  manchesterunited: manchesterunited,
  wolverhamptonfc: wolverhamptonfc,
  sheffieldunitedfc: sheffieldunitedfc,
  lutontownfc: lutontownfc,
};
