import { TeamViewModel } from "../types";
import useMediaQuery from "../hooks/ueMediaQuery";
import { Box } from "grommet";
import { capitalizeFirstLetter } from "../utils";
import { Calendar } from "grommet-icons";
import { colors } from "../assets/theme";
import { Previous } from "./svgs/Previous";
import { Next } from "./svgs/Next";
import React from "react";
import styled from "styled-components";

const TeamLabel = styled.span<{ isDesktop: boolean }>`
  font-weight: bold;
  font-size: ${({ isDesktop }) => (isDesktop ? "24px" : "20px")};
  color: ${() => colors.plDarkPurple06};
  border-radius: 5px;
`;

const HeaderContainer = styled(Box)<{ isDesktop: boolean }>`
  align-items: ${({ isDesktop }) => (isDesktop ? "center" : "flex-start")};
  flex-direction: ${({ isDesktop }) => (isDesktop ? "row" : "column")};
  justify-content: space-between;
  color: ${() => colors.plDarkPurple06};
  padding: 12px;
  background-color: ${colors.plWhite};
  border-radius: 5px;
  border: ${() => `1px solid ${colors.plDarkGrey}`};
  position: sticky;
  z-index: 3;
  top: ${({ isDesktop }) => (isDesktop ? "170px" : "48px")};
`;

export const TeamWeekCardHeader = ({
  team,
  onFetchPreviousWeek,
  onFetchNextWeek,
  intervalString,
  isLoading,
  showResetButton,
  onResetToCurrentTimeSpan,
}: {
  team: TeamViewModel;
  onFetchPreviousWeek: () => void;
  onFetchNextWeek: () => void;
  intervalString: string;
  isLoading: boolean;
  showResetButton: boolean;
  onResetToCurrentTimeSpan: () => void;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return (
    <HeaderContainer align="center" isDesktop={isDesktop}>
      <Box>
        <TeamLabel isDesktop={isDesktop}>
          {capitalizeFirstLetter(team.name)}
        </TeamLabel>
      </Box>
      <Box
        style={{ fontSize: isDesktop ? "18px" : "16px" }}
        direction="row"
        align="center"
        flex="grow"
        justify="end"
        margin={{
          left: isDesktop ? "10px" : "0",
          top: isDesktop ? "0" : "5px",
        }}
      >
        <Box
          margin={{ right: "medium" }}
          pad={isDesktop ? "0 12px" : "0"}
          direction="row"
          align="center"
        >
          <Calendar
            style={{
              marginRight: "10px",
              height: "14px",
              width: "14px",
            }}
            color={colors.plDarkPurple06}
          />
          {intervalString}
          {showResetButton ? (
            <span
              style={{
                marginLeft: "5px",
                fontWeight: "bold",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={onResetToCurrentTimeSpan}
            >
              (today)
            </span>
          ) : null}
        </Box>
        <Box direction="row">
          <Previous isLoading={isLoading} onClick={onFetchPreviousWeek} />
          <Next isLoading={isLoading} onClick={onFetchNextWeek} />
        </Box>
      </Box>
    </HeaderContainer>
  );
};
