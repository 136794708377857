import { useEffect, useMemo, useState } from "react";
import { AppUserViewModel } from "../types";
import AppUserService from "../services/appUserService";

export const useGetAppUser = () => {
  const [appUser, setAppUser] = useState<AppUserViewModel | undefined>(
    {} as AppUserViewModel
  );
  const [isFetchingAppUser, setIsFetchingAppUser] = useState<boolean>(false);

  const appUserService = useMemo(() => new AppUserService(), []);

  const fetchCurrentUser = async () => {
    setIsFetchingAppUser(true);
    const currentUser = await appUserService.getCurrentUser();
    setAppUser(currentUser);
    setIsFetchingAppUser(false);
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const refetchUser = () => {
    fetchCurrentUser();
  };

  return { appUser, refetchUser, isFetchingAppUser };
};
