import { OccurrenceType } from "../enums";
import React, { useEffect, useState } from "react";
import { EventLabel, EventOption, EventOptionContainer } from "./EventOption";
import styled from "styled-components";
import { colors } from "../assets/theme";
import { capitalizeFirstLetter } from "../utils";
import { Box } from "grommet";
import { OccurrenceViewModel } from "../types";

type SecondaryTagFieldType = "radio" | "text";

const clubSecondaryTags = [
  { label: "Audit", fieldType: "radio", isDefault: false },
  { label: "Coaching", fieldType: "radio", isDefault: false },
  {
    label: "Education",
    fieldType: "radio",
    isDefault: false,
  },
  {
    label: "Games Programme",
    fieldType: "radio",
    isDefault: false,
  },
  {
    label: "Leadership & Management",
    fieldType: "radio",
    isDefault: false,
  },
  { label: "Meeting", fieldType: "radio", isDefault: true },
  {
    label: "Operations",
    fieldType: "radio",
    isDefault: false,
  },
  {
    label: "Performance",
    fieldType: "radio",
    isDefault: false,
  },
  {
    label: "Player Care",
    fieldType: "radio",
    isDefault: false,
  },
  {
    label: "Safeguarding",
    fieldType: "radio",
    isDefault: false,
  },
  { label: "Other", fieldType: "text", isDefault: false },
];

export const secondaryTags = {
  leave: [
    {
      label: "Annual Leave",
      fieldType: "radio",

      isDefault: true,
    },
    {
      label: "Bank Holiday",
      fieldType: "radio",

      isDefault: false,
    },
    { label: "Other", fieldType: "text", isDefault: false },
  ],
  londonbrunel: [{ label: "Location", fieldType: "text", isDefault: false }],
  brunel: [{ label: "Location", fieldType: "text", isDefault: false }],
  newyork: [{ label: "Location", fieldType: "text", isDefault: false }],
  singapore: [{ label: "Location", fieldType: "text", isDefault: false }],
  travel: [{ label: "Reason", fieldType: "text", isDefault: false }],
  remote: [
    { label: "Home", fieldType: "radio", isDefault: true },
    { label: "Other", fieldType: "text", isDefault: false },
  ],
  parentalleave: [],
  sickleave: [],
  arsenalfc: clubSecondaryTags,
  astonvillafc: clubSecondaryTags,
  afcbournemouth: clubSecondaryTags,
  fulhamfc: clubSecondaryTags,
  nottinghamforestfc: clubSecondaryTags,
  evertonfc: clubSecondaryTags,
  newcastleunited: clubSecondaryTags,
  norwichfc: clubSecondaryTags,
  brentfordfc: clubSecondaryTags,
  leedsunitedfc: clubSecondaryTags,
  southamptonfc: clubSecondaryTags,
  brightonandhovefc: clubSecondaryTags,
  leicestercityfc: clubSecondaryTags,
  tottenhamhotspurfc: clubSecondaryTags,
  burnleyfc: clubSecondaryTags,
  liverpoolfc: clubSecondaryTags,
  watfordfc: clubSecondaryTags,
  chelseafc: clubSecondaryTags,
  manchestercity: clubSecondaryTags,
  westhamunited: clubSecondaryTags,
  crystalpalacefc: clubSecondaryTags,
  manchesterunited: clubSecondaryTags,
  wolverhamptonfc: clubSecondaryTags,
  sheffieldunitedfc: clubSecondaryTags,
  lutontownfc: clubSecondaryTags,
} as unknown as Record<
  OccurrenceType,
  {
    label: string;
    fieldType: SecondaryTagFieldType;
    isDefault: boolean;
  }[]
>;

const StyledTextInput = styled.input`
  position: relative;
  border-radius: 5px;
  width: 150px;
  font-size: 16px;
  padding: 5px;
  border: ${() => `1px solid ${colors.plStone1}`};
`;

export const EventDescriptionList = ({
  occurrenceType,
  onChangeSecondaryTag,
  disabled,
  selectedDateOccurrence,
}: {
  occurrenceType: OccurrenceType;
  onChangeSecondaryTag: (secondaryTag: string) => void;
  disabled: boolean;
  selectedDateOccurrence: OccurrenceViewModel[];
}) => {
  const [selectedSecondaryTag, setSelectedSecondaryTag] = useState<string>("");
  const [freeTextInputValue, setFreeTextInputValue] = useState<string>("");
  const [defaultSecondaryTag, setDefaultSecondaryTag] = useState<string>("");

  useEffect(() => {
    const formattedOccurrenceType = occurrenceType.replace(/\s/g, "");
    const sameEvent = selectedDateOccurrence
      .map((occ) => occ.type)
      .find((type) => type === formattedOccurrenceType);
    const sameSecondary = selectedDateOccurrence.find(
      (occ) => occ.type === sameEvent
    )?.description;

    const defaultSecondaryTag =
      sameSecondary ||
      (secondaryTags[formattedOccurrenceType as OccurrenceType].length > 0 &&
        secondaryTags[formattedOccurrenceType as OccurrenceType].find(
          (tag) => tag.isDefault
        )?.label);

    if (!!defaultSecondaryTag) {
      if (
        (!!sameEvent &&
          secondaryTags[formattedOccurrenceType as OccurrenceType]
            .map((occ) => occ.label)
            .indexOf(sameSecondary!) === -1) ||
        sameSecondary === "Other"
      ) {
        setDefaultSecondaryTag("Other");
        setFreeTextInputValue(sameSecondary!);
      } else {
        setDefaultSecondaryTag(defaultSecondaryTag);
        setFreeTextInputValue("");
      }
      setSelectedSecondaryTag(defaultSecondaryTag || "");
      // if there's a default secondary tag, the form can be submitted
      onChangeSecondaryTag(defaultSecondaryTag || "");
    }
  }, [occurrenceType]);

  return (
    <>
      {secondaryTags[occurrenceType.replace(/\s/g, "") as OccurrenceType].map(
        (secondaryTag) =>
          secondaryTag.fieldType === "radio" ? (
            <EventOption
              key={secondaryTag.label}
              disabled={disabled}
              eventType={selectedSecondaryTag as OccurrenceType}
              label={secondaryTag.label as OccurrenceType}
              onClick={(label) => {
                setFreeTextInputValue("");
                setSelectedSecondaryTag(label);
                onChangeSecondaryTag(label);
              }}
            />
          ) : secondaryTag.fieldType === "text" ? (
            <EventOptionContainer key={secondaryTag.label}>
              <Box direction="row" align="center">
                <EventLabel>
                  {capitalizeFirstLetter(secondaryTag.label)}
                </EventLabel>
              </Box>
              <StyledTextInput
                maxLength={20}
                disabled={disabled}
                value={freeTextInputValue}
                onChange={(e) => {
                  setFreeTextInputValue(e.target.value);
                  setSelectedSecondaryTag(e.target.value);
                  onChangeSecondaryTag(e.target.value);
                }}
                // check user hasn't input empty string
                onBlur={() => {
                  if (!!freeTextInputValue.trim()) {
                    setFreeTextInputValue(freeTextInputValue.trim());
                    setSelectedSecondaryTag(freeTextInputValue.trim());
                    onChangeSecondaryTag(freeTextInputValue.trim());
                  } else {
                    setFreeTextInputValue("");
                    setSelectedSecondaryTag(defaultSecondaryTag);
                    onChangeSecondaryTag(defaultSecondaryTag);
                  }
                }}
              />
            </EventOptionContainer>
          ) : null
      )}
    </>
  );
};
