import { AppUserViewModel } from "../../types";
import React, { useEffect, useMemo, useState } from "react";
import { SettingItem } from "./shared/SettingItem";
import { Box, Notification, Paragraph, Select, Tab, Tabs, Text } from "grommet";
import styled from "styled-components";
import { capitalizeFirstLetter, sortWithUsersEntityFirst } from "../../utils";
import { useFetchDepartmentUsers } from "../../hooks/useFetchDepartmentUsers";
import { useGetAppUser } from "../../hooks/useGetAppUser";
import { SaveButton } from "../SaveButton";
import AppUserService from "../../services/appUserService";
import { Info } from "grommet-icons";
import { colors } from "../../assets/theme";
import { Link, useNavigate } from "react-router-dom";

const MAX_DELEGATES_TO = 2;

const StyledTab = styled(Tab)`
  padding: 5px 10px;
  height: 100%;
  div {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  span {
    padding: 0 10px;
    font-weight: bold;
    border-radius: 5px;
  }
`;

export const DelegatesSettings = ({ onClose }: { onClose: () => void }) => {
  const appUserService = useMemo(() => new AppUserService(), []);
  const { allUsers } = useFetchDepartmentUsers();
  const { appUser } = useGetAppUser();

  const [appUsersNamesOptions, setAppUsersNamesOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const [delegatesTo, setDelegatesTo] = useState<string[]>([]);
  const [delegatesFor, setDelegatesFor] = useState<AppUserViewModel[]>([]);

  const [sameDelegateTwiceError, setSameDelegateTwiceError] =
    useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);

  const [filterValue, setFilterValue] = useState<string>("");

  const [showToast, setShowToast] = useState<{
    show: boolean;
    message: string;
  }>();

  const onSave = async () => {
    setSaving(true);

    if (appUser) {
      try {
        await appUserService.updateAppUserDelegates(appUser.id, {
          delegatesTo,
        });

        setShowToast({
          show: true,
          message: "Settings saved.",
        });
      } catch (err) {
        setShowToast({
          show: true,
          message: "Ooops! " + err,
        });
      }
      setSaving(false);
    }
  };

  // manage users dropdown and filtering
  useEffect(() => {
    if (allUsers && appUser) {
      const userFound = allUsers.find((u) => u.id === appUser!.id);

      if (userFound) {
        setDelegatesTo(userFound.delegatesTo.map((u) => u.id));
      }

      const delegateFor = allUsers.filter((u) =>
        u.delegatesTo.flatMap((delegate) => delegate.id).includes(appUser.id)
      );
      setDelegatesFor(delegateFor);

      const appUserNamesOptions = allUsers
        .filter((u) => u.id !== appUser.id)
        .map((user) => ({
          id: user.id,
          name: capitalizeFirstLetter(user.name.replace(/[.]/g, " ")),
        }));

      if (!filterValue) {
        setAppUsersNamesOptions(
          sortWithUsersEntityFirst(appUser.id, appUserNamesOptions)
        );
      } else {
        setAppUsersNamesOptions(
          sortWithUsersEntityFirst(
            appUser.id,
            appUserNamesOptions.filter((opt) =>
              opt.name
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(filterValue.toLowerCase().replace(/\s/g, ""))
            )
          )
        );
      }
    }
  }, [allUsers, appUser?.id, filterValue]);

  useEffect(() => {
    setSameDelegateTwiceError(false);
    // check if user hasn't selected the same person twice
    if (new Set(delegatesTo).size !== delegatesTo.length) {
      setSameDelegateTwiceError(true);
      return;
    }
  }, [delegatesTo]);

  const getDelegate = (index: number) => {
    if (delegatesTo?.length > index) {
      const userFound = allUsers.find((u) => u.id === delegatesTo[index]);

      if (userFound) {
        return userFound;
      }
    }
    return;
  };

  return (
    <>
      <SettingItem>
        <Box direction="column" width="100%" margin={{ vertical: "small" }}>
          <Tabs justify="start">
            <StyledTab title="To">
              <Box width="100%">
                <Paragraph margin="none" size="small">
                  Below are your delegate(s):
                </Paragraph>
              </Box>

              <Box margin={{ top: "medium", bottom: "medium" }}>
                {Array.from({ length: MAX_DELEGATES_TO }).map((_, i) => {
                  return (
                    <Box
                      key={i}
                      pad="small"
                      direction="row"
                      justify="between"
                      width="100%"
                    >
                      <Box>Delegate {i + 1}</Box>
                      <Select
                        clear={true}
                        style={{
                          height: "20px",
                          width: "200px",
                        }}
                        size="small"
                        options={appUsersNamesOptions.map((user) => user.name)}
                        value={
                          capitalizeFirstLetter(getDelegate(i)?.name!) ||
                          undefined
                        }
                        onChange={({ option }) => {
                          const selectedDelegate = allUsers?.find(
                            (user) =>
                              capitalizeFirstLetter(
                                user.name.replace(/[.]/g, " ")
                              ) === option
                          );

                          const delegatesToCopy = [...delegatesTo];
                          if (selectedDelegate) {
                            // replace existing delegate with new selection
                            delegatesToCopy.splice(i, 1, selectedDelegate.id);
                          } else {
                            // user cleared selection, hence just remove him/she
                            delegatesToCopy.splice(i, 1);
                          }

                          setDelegatesTo(delegatesToCopy);
                          setFilterValue("");
                        }}
                        onSearch={(filterValue: string) =>
                          setFilterValue(filterValue)
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
              {sameDelegateTwiceError && (
                <Box margin={{ bottom: "small" }}>
                  <Paragraph margin="none" size="small" color="red">
                    You can't select the same delegate twice.
                  </Paragraph>
                </Box>
              )}

              <SaveButton
                style={{ width: "100%" }}
                onClick={onSave}
                disabled={sameDelegateTwiceError || saving}
              >
                Save
              </SaveButton>
            </StyledTab>
            <StyledTab title="For">
              <Box width="100%" margin={{ bottom: "small" }}>
                <Paragraph margin="none" size="small">
                  Below are the people you are a delegate for:
                </Paragraph>
              </Box>
              <Box
                pad="small"
                style={{
                  overflowY: "auto",
                  maxHeight: "230px",
                  borderRadius: "5px",
                }}
                background={colors.plStone05}
              >
                {delegatesFor.length > 0 ? (
                  delegatesFor.map((u) => (
                    <Link
                      to="/calendar"
                      state={{ appUser: u }}
                      style={{ textDecoration: "none", padding: "5px 0" }}
                      key={u.id}
                      onClick={onClose}
                    >
                      {u.name}
                    </Link>
                  ))
                ) : (
                  <Text>There are no users to show.</Text>
                )}
              </Box>
            </StyledTab>
          </Tabs>
        </Box>
      </SettingItem>
      {showToast?.show ? (
        <Notification
          icon={<Info />}
          toast={{
            autoClose: true,
            position: "top",
          }}
          time={3000}
          status="info"
          title={showToast.message}
          onClose={() => setShowToast({ show: false, message: "" })}
        />
      ) : null}
    </>
  );
};
