import styled from "styled-components";
import { PremierLeagueLogoWhite } from "../assets/PremierLeagueLogoWhite";
import { Loading } from "./Loading";
import { Text } from "grommet";
import { colors } from "../assets/theme";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const LoadingScreen = () => {
  return (
    <Container>
      <PremierLeagueLogoWhite size="large" />
      <Loading />
      <Text
        style={{ fontFamily: "Premier League" }}
        color={colors.plDarkPurple}
        weight="bold"
      >
        Loading...
      </Text>
    </Container>
  );
};
