import { useEffect, useMemo, useState } from "react";
import { Department } from "../types";
import DepartmentService from "../services/departmentService";

export const useGetDepartment = (departmentId?: string) => {
  const [department, setDepartment] = useState<Department>({} as Department);
  const departmentService = useMemo(() => new DepartmentService(), []);

  useEffect(() => {
    if (departmentId) {
      const fetchDept = async () => {
        const dept = await departmentService.getDepartment({
          id: departmentId,
        });
        setDepartment(dept);
      };
      fetchDept();
    }
  }, [departmentId]);

  return { department };
};
