import styled from "styled-components";
import { colors } from "../assets/theme";

export const DeleteButton = styled.button`
  background-color: ${({ disabled }) =>
    !disabled ? colors.plMagenta01 : colors.plStone02};
  border-radius: 4px;
  min-height: fit-content;
  color: ${({ disabled }) => (!disabled ? colors.plMagenta1 : colors.plGrey02)};
  border: none;
  font-family: "Premier League";
  padding: 7px 15px;
  cursor: pointer;
  margin: 10px 0;
`;
