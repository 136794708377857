import React from "react";
import { OccurrenceDuration } from "../../../enums";
import { Box } from "grommet";

export const Sick35 = ({
  occurrenceDuration,
  positionAbsolute,
}: {
  occurrenceDuration?: OccurrenceDuration;
  positionAbsolute?: boolean;
}) => {
  return (
    <Box
      width="35px"
      height="35px"
      justify="center"
      align="center"
      style={{
        position: positionAbsolute ? "absolute" : "unset",
        clip:
          occurrenceDuration === OccurrenceDuration?.am
            ? "rect(auto,17.5px,auto,auto)"
            : occurrenceDuration === OccurrenceDuration?.pm
            ? "rect(auto,auto,auto,17.5px)"
            : "unset",
        background: "yellowgreen",
      }}
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.619 8.75C21.3095 8.75 20.2381 7.625 20.2381 6.25C20.2381 4.875 22.619 1.25 22.619 1.25C22.619 1.25 25 4.875 25 6.25C25 7.625 23.9286 8.75 22.619 8.75ZM18.4524 6.25C18.4524 5.3375 18.9405 4.1125 19.5476 2.925C17.4762 1.1 14.8095 0 11.8929 0C5.32143 0 0 5.6 0 12.5C0 19.4 5.32143 25 11.8929 25C18.4762 25 23.8095 19.4 23.8095 12.5C23.8095 11.8125 23.7381 11.1375 23.6429 10.475C23.3095 10.5625 22.9762 10.625 22.619 10.625C20.3214 10.625 18.4524 8.6625 18.4524 6.25ZM16.2143 6.725L17.4762 8.05L16.2143 9.375L17.4762 10.7L16.2143 12.025L13.6905 9.375L16.2143 6.725ZM6.33333 8.05L7.59524 6.725L10.119 9.375L7.59524 12.025L6.33333 10.7L7.59524 9.375L6.33333 8.05ZM16 18.75C15.1786 17.2625 13.6548 16.25 11.9048 16.25C10.1548 16.25 8.63095 17.2625 7.80952 18.75H5.80952C6.16667 17.8 6.71429 16.9625 7.40476 16.2625L3.85714 14.125C3.32143 14.45 2.65476 14.475 2.08333 14.125C1.22619 13.6125 0.940476 12.4625 1.42857 11.5625C1.91667 10.6625 3.0119 10.3625 3.86905 10.875C4.44048 11.225 4.75 11.85 4.75 12.4875L9.0119 15.075C9.88095 14.625 10.8571 14.375 11.9048 14.375C14.6786 14.375 17.0476 16.1875 18 18.75H16Z"
          fill="black"
          fillOpacity="0.55"
        />
      </svg>
    </Box>
  );
};
