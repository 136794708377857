import axios from "axios";
import { AppUser, AppUserViewModel } from "../types";

export default class TeamService {
  getTeamById = async (payload: { teamId: string }) => {
    const { teamId } = payload;

    const team = await axios.get(`/teams/${teamId}`).then((res) => res.data);

    const teamWithoutHiddenUsers = {
      ...team,
      appUsers: team.appUsers
        .filter((user: AppUser) => user.plasActive && !user.hidden)
        .map((user: AppUserViewModel) => ({
          ...user,
          name: user.name.replace(/[.]/g, " ").replace(/^(ext)/gi, ""),
        })),
    };

    return teamWithoutHiddenUsers;
  };
}
