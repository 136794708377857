import React, { ReactNode } from "react";
import { Box, Tip } from "grommet";
import { colors } from "../assets/theme";
import { AppUserViewModel } from "../types";
import { AvatarHeader } from "./AvatarHeader";
import useMediaQuery from "../hooks/ueMediaQuery";

export const getAppUserTooltip = (appUser: AppUserViewModel) => {
  return (
    <Box round="xsmall">
      <Box
        direction="column"
        flex="grow"
        pad="medium"
        background={colors.plStone02}
      >
        <AvatarHeader appUser={appUser} extended />
      </Box>
    </Box>
  );
};

export const AppUserInfoTooltip = ({
  appUser,
  children,
}: {
  children: ReactNode;
  appUser: AppUserViewModel;
}) => {
  const isDesktop = useMediaQuery("(min-width:650px)");

  return isDesktop ? (
    <Tip content={getAppUserTooltip(appUser)}>{children}</Tip>
  ) : (
    <>{children}</>
  );
};
