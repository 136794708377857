import { TeamCard } from "./TeamCard";
import React from "react";
import { DefaultView } from "../enums";
import {
  AppUserViewModel,
  DateInterval,
  OccurrenceViewModel,
  TeamViewModel,
} from "../types";

export const PinnedUsersTeamCard = ({
  filterValue,
  allUsers,
  defaultView,
  appUser,
  pinnedUsers,
  onFavouritesClick,
  defaultDatesInterval,
  onSelectDate,
  pinnedUsersTeam,
  onChangeTimeInterval,
  isLoadingPinnedUsersData,
  isMutatingPinnedUsers,
  isRefetchingPinnedUsersData,
}: {
  filterValue: string;
  defaultView?: { label: DefaultView; isActive: boolean }[];
  appUser: AppUserViewModel;
  pinnedUsers: AppUserViewModel[];
  allUsers: AppUserViewModel[];
  onFavouritesClick: (newFavUser: AppUserViewModel) => void;
  defaultDatesInterval: DateInterval[];
  onSelectDate: (
    date: string,
    occurrences: OccurrenceViewModel[],
    targetUser: AppUserViewModel
  ) => void;
  pinnedUsersTeam: TeamViewModel;
  onChangeTimeInterval: (
    teamId: string,
    newStartDate: Date,
    newToDate: Date
  ) => void;
  isLoadingPinnedUsersData: boolean;
  isMutatingPinnedUsers: boolean;
  isRefetchingPinnedUsersData: boolean;
}) => {
  return (
    <TeamCard
      allUsers={allUsers}
      showPinOnHover
      filterValue={filterValue}
      activeTimeSpan={
        defaultView?.filter((defaultView) => defaultView.isActive)[0].label
      }
      appUserRole={appUser.role}
      favouriteAppUsers={pinnedUsers}
      onFavouritesClick={onFavouritesClick}
      onSelectDate={onSelectDate}
      showResetButton={
        defaultDatesInterval[0]?.date.setHours(0, 0, 0, 0) !==
        pinnedUsersTeam.interval.fromDate.setHours(0, 0, 0, 0)
      }
      onResetToCurrentTimeSpan={() =>
        onChangeTimeInterval(
          pinnedUsersTeam.id,
          defaultDatesInterval[0]?.date,
          defaultDatesInterval[defaultDatesInterval.length - 1]?.date
        )
      }
      appUserId={appUser?.id}
      isLoading={
        isLoadingPinnedUsersData ||
        isMutatingPinnedUsers ||
        isRefetchingPinnedUsersData
      }
      key={pinnedUsersTeam.id}
      team={pinnedUsersTeam}
      onChangeTimeInterval={onChangeTimeInterval}
    />
  );
};
